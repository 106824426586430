import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, formatCurrent, parentLink, sortByNumber, sortWithDate } from "../../../theme/helpers";
import { ContentHeader } from "../../../theme/layout/components/content";
import { FilterDropdown, InputDate, KTSVG } from "../../../theme/partials";
import { useHospdiningareasections2 } from "../../modules/api/transactions";
import { useStoreMode } from "../../modules/auth/core/store";
import { AuthLayoutMode } from "../mode/authMode";
import { MBar } from "./tabs/live";
import { useTranslation } from "react-i18next";
import { popupAlert } from "../../modules/messages";

const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});

export const checkInternetConnection = async () => {
  try {
    let url = window.location.origin
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    return false;
  }
};
const TableService = () => {
  
    const navigate = useNavigate();
    const [tableData,setTableData] = useState<any>([])
    const [transColors,setTransColors] = useState<any>([])
    
    const {pathname} = useLocation()
    // const { currentUser } = useAuth() as any
    const storeMode = useStoreMode((state:any) => state)
    const [showLogin,setShowLogin] = useState(false)
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    // const { data: dataQuery, isSuccess, refetch: refetchCurrent } = useTransactions(`${staffInfo.merchantid}/current?expand=hosptransactionlines&staffcode=${staffInfo.code}`)
    // const { data: dataHospdiningareasections, isFetching, isSuccess: succesSdataHospdiningareasections, refetch: refetchTable } = useHospdiningareasections2(staffInfo.merchantid)
    
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}
    useEffect(() => {
      const handleFocus = () => {
        console.log('Screen is focused');
        refetchTable()
      };
      
      const handleBlur = () => {
        console.log('Screen is blurred');
      };
  
      // Thêm sự kiện lắng nghe focus và blur
      window.addEventListener('focus', handleFocus);
      window.addEventListener('blur', handleBlur);
  
      // Dọn dẹp sự kiện khi component bị unmount
      return () => {
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
      };
    }, []);
    const ws = useRef<any>(null);
    const intervalId = useRef<any>(null);
    const [dataNotify,setDataNotify] = useState(null)

    const connectWebSocket = () => {
      const url = process.env.REACT_APP_URL_API as string
      ws.current = new WebSocket(url);

      ws.current.onopen = () => {
          console.log('WebSocket connected');
          // Send a keep-alive message every 30 seconds
          intervalId.current = setInterval(() => {
              if (ws.current.readyState === WebSocket.OPEN) {
                  ws.current.send(JSON.stringify({ type: 'keep-alive' }));
              }
          }, 30000);
      };
      ws.current.onmessage = (event: any) => {
        let wsdata = event.data;

        if (wsdata) {
          wsdata = JSON.parse(wsdata);
          console.log('WebSocket server wsdata', wsdata) //wsdata {"id":"69a33d3c-ad3a-448f-8623-fe12c65effa2","code":"24060000313","kitchenstatus":"Not Sent","transactionstatus":"Open"}
          if(wsdata.merchantcode == staffInfo.Merchant?.merchantcode) {
            refetchTable()
            // console.log('aaaaaaaaa')
            setDataNotify(wsdata)
          }
          
        }
      };

      ws.current.onclose = () => {
          // console.log('WebSocket closed.');
          clearInterval(intervalId.current);
          if(window.location.pathname == '/mode/table-service') {
            console.log('WebSocket Reconnecting...')
            setTimeout(()=>{
              connectWebSocket()
            }, 1000); // Reconnect after 1 second
          }
      };

      ws.current.onerror = (error: any) => {
          console.log('WebSocket error:', error);
          ws.current.close();
      };
    };
    useEffect(() => {
      getHospdiningareasections()
      connectWebSocket();
      return () => {
        ws.current.close();
        clearInterval(intervalId.current);
      };
    }, []);
    // const [dataHospdiningareasections,setDataHospdiningareasections] = useState<any>([])
    const refetchTable = () => {
      getHospdiningareasections()
    }
    const getHospdiningareasections = async() => {
      try {
          const urlApi = `smarthub/hospdiningareasections/merchant2/${staffInfo.merchantid}`
          ApiService.get(urlApi)
          .then(({ data }) => {
            if(data) {
              // setDataHospdiningareasections(data)
              let a = [] as any
              let tempTabs = sortByNumber(data,'seq')
              setTabs(tempTabs)
              data.forEach((i: any) => {
                i.DiningTables.forEach((item: any) => {
                  if(item.Trans && item.Trans.length > 1) {
                    item.Trans = sortByNumber(item.Trans,'code','DESC')
                  }
                  a = a.concat(item.Trans?.map((h: any) => {
                    return {
                      ...h,
                      HistoryEntries: item.HistoryEntries,
                      tablename: item.tablename,
                      tableno: item.tableno,
                      tableid: item.id,
                      sectionname: i.sectionname,
                      "sectionid": i.id,
                      "sectioncode": i.sectioncode,
                      islocked: item.islocked,
                    }
                  })||[])
                })
              })
              let tempData = a//sortWithDate(a,{order:'desc',sort:'transactiontime'})
              // console.log('tempData',tempData)
              getTransactionColors(tempData.map((i: any) => i.id))
              setTableData(tempData)
            }
          })
          .catch((error) => {
            ApiShowError(error,'then getHospdiningareasections') 
          });

      } catch (error) { ApiShowError(error,'getHospdiningareasections') }
    }
    const getTransactionColors = async(ids: any) => {
      try {
          const urlApi = `smarthub/hosptransactions/get/transactioncolors`
          let tempData = {
              "transactionids": ids,
          }
          ApiService.post(urlApi, tempData)
          .then(({ data }) => {
            if(data) {
                setTransColors(data)
            }
          })
          .catch((error) => {
            ApiShowError(error,'then getTransactionColors') 
          });

      } catch (error) { ApiShowError(error,'getTransactionColors') }
    }
    // useEffect(() => {
    //   console.log('ádadada isFetching',!isFetching)
      
    // }, [isFetching,dataHospdiningareasections])
    
    const [isOnline,setIsOnline] = useState(navigator.onLine)
    useEffect(() => {
      // Hàm cập nhật trạng thái online/offline
      const updateOnlineStatus = async () => {
        const onlineStatus = await checkInternetConnection();
        setIsOnline(onlineStatus);
      };
  
      // Gọi hàm kiểm tra kết nối ban đầu
      updateOnlineStatus();
  
      // Lắng nghe sự thay đổi của sự kiện online/offline từ trình duyệt
      const handleOnline = () => {
        updateOnlineStatus();
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      // Cleanup event listener khi component unmount
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };
    }, []);
    const [tabs, setTabs] = useState<any>([])
    
    const [filter, setFilter] = useState<any>(null)
    const dataForTab = () => {
      let a = tableData?.filter((item: any) => item.transactionstatus != 'Cancel' ||  item.transactionstatus != 'Complete')
      // console.log('aaaaaaaa',a)
      return a
    }
   
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })

    // useEffect(() => {
    //     if (isSuccess && !isFetching) {
    //         reset(data)
    //     }
    // }, [isFetching])
    const { t } = useTranslation()
    const filterBtn = <>
    <FilterDropdown
    btnTemplate={<div className="btn btn-light-primary btn-active-primary btn-sm btn-icon">
      <KTSVG path={'/media/icons/custom/filter.svg'} className={clsx('svg-icon-2 svg-icon')} />
    </div>
    }
    width="350"
    onClose={() => {
        setFilter(undefined)
        reset({})
        }
    }
    handleSubmit={handleSubmit}
    onSubmit={(data:any) => {
      // console.log('ấda',data)
        setFilter(data)
       
    }} >
        <div className="container-fluid mb-2">
            <div className="row">
              <div className="col-12 fs-5 mb-3">{t('table-service-deep.all-checks')}</div>
              <div className="col-12 fs-5 mb-3">{t('table-service-deep.dinner-behavior')}</div>
              <div className="col-12 col-sm-6">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('dinner_in', {
                        onChange: (e) => {} })} value='1' id="dinner_in" />
                  <label htmlFor="dinner_in">{t('table-service-deep.dinner-in')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('takeout_in', {
                        onChange: (e) => {} })} value={1} id='takeout_in' />
                  <label htmlFor="takeout_in">{t('table-service-deep.takeout-in')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6  mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_delivery', {
                        onChange: (e) => {} })} value='1' id='is_delivery' />
                  <label htmlFor="is_delivery">{t('table-service-deep.delivery')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_curbside', {
                        onChange: (e) => {} })} value='1' id='is_curbside' />
                  <label htmlFor="is_curbside">{t('table-service-deep.curbside')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('no_behavior', {
                        onChange: (e) => {} })} value='1' id='no_behavior' />
                  <label htmlFor="no_behavior">{t('table-service-deep.no-behavior')}</label>
                </div>
              </div>
            </div>
        </div>
      </FilterDropdown>
    </>
    const toolbar = <div className="d-flex flex-center gap-5">
      <span className="text-primary">{t('table-service-deep.version')} {process.env.REACT_APP_VERSION}</span>
      {
        staffInfo && 
        <span className="text-primary"><i className="bi bi-person-hearts text-reset me-1"></i>{staffInfo.code} | {staffInfo.fullname}</span>
      }
    {
      isOnline ?
      <span className="text-primary cursor-pointer"><i className="bi bi-wifi me-2 text-reset"></i> {t('online')}</span>
      :
      <span className="text-gray cursor-pointer"><i className="bi bi-wifi-off me-2 text-reset"></i> {t('offline')}</span>
    }
      {/* {staffPermissionGroupInfo.isopensalepos && 
        <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer" onClick={e=>{
          // setIsNewOrder(true)
          // setEdit(!edit)
        }}><i className="bi bi-search me-2"></i> Lookups</span>
      } */}
      <Dropdown>
        <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
          <i className="bi bi-three-dots-vertical" />
        </Dropdown.Toggle>
        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-2' >
            
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <span className=''>
                  <span className="menu-link">{t('table-service-deep.manager-online-orders')}</span>
                </span>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <span className=''> 
                  <span className="menu-link">{t('user-guide')}</span>
                </span>
            </Dropdown.Item>
            
            
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <span onClick={e=>window.location.reload()} className='text-black'> 
                  <span className="menu-link d-flex gap-2 align-items-center">{t('order-deep.refresh')}</span>
                </span>
            </Dropdown.Item>
            {/* <Dropdown.Item as='div'  className={clsx('menu-item')} >
                <span className=''> 
                  <span className="menu-link">{t('table-service-deep.version')} {process.env.REACT_APP_VERSION}</span>
                </span>
            </Dropdown.Item> */}
        </Dropdown.Menu>
    </Dropdown>
    <span onClick={e=> {
      setShowLogin(true)
      // storeMode.setMode(null)
      // console.log('storeMode',storeMode)
    }} className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer"><i className="bi bi-arrow-left-right me-2"></i>{t('table-service-deep.switch-user')}</span>
    
    </div>
    

    const onSubmitFilter:any = (data: any) => {
        if(!filter) return data
        let tempData = data.map((item:any)=> item)
        if(filter.statusLocal ) {
            tempData = tempData.filter((i:any)=> i.statusLocal == filter.statusLocal)
        }
        if(filter.departmentcode) {
            tempData = tempData.filter((i:any)=> i.departmentid == filter.departmentid)
        }
        if(filter.createdby) {
            tempData = tempData.filter((i:any)=> i.createdby == filter.createdby)
        }
        let fromdate = filter.fromdate ? filter.fromdate: new Date()
        if(fromdate) {
            fromdate = new Date(fromdate).setHours(0,0,0)
        }
        let todate = filter.todate? filter.todate[0]: null 
        if(todate) {
            todate = new Date(todate).setHours(23,59,59)
        }
        if(todate) {
            tempData = tempData.filter((i:any)=> new Date(i.createdat).getTime() <= todate && new Date(i.createdat).getTime() >= fromdate)
        }
        // console.log('tempData',tempData)
        return tempData
    }

    
    const [edit ,setEdit] = useState<any>(false)
    const [isNewOrder ,setIsNewOrder] = useState<any>(false)
    
    
    return <>
        <ContentHeader title={t('table-service')} linkBack={'/mode'} items={[{ title: t('mode'), path: parentLink(pathname) }]} elements={toolbar} />
        {/* <div className=' border-gray-300 mh-auto pb-0'>
              <div className=' mb-0 row'>
                <div className="col-7">
                  <div
                    className='card flex-fill d-flex flex-row text-nowrap scrollx-auto tab-custom-hub'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-width='auto'
                    data-kt-scroll-offset='0'
                  >
                    {tabs.map((item, index) => {
                      return (<span
                        key={index}
                        id={item.id}
                        onClick={() => {
                          setTab(item.id)
                          refetch()
                        }}
                        className={clsx(
                          ' cursor-pointer item-tab',
                          tab != item.id && 'text-gray-600 ',
                          tab == item.id && 'active',
                          index == 0 && ''
                        )}>
                        {item.name} <span className="number-circle bg-gray">{item.lengData}</span>
                      </span>)
                    })}
                  </div>
                </div>
                
              </div>
              
              {tabs.map((item, index) => {
                  return <DivRoot key={index}>
                      {item.id == tab && item.component}
                  </DivRoot>
              })}
            </div> */}
        <MBar dataNotify={dataNotify} refetchTable={refetchTable} transColors={transColors} isNewOrder={isNewOrder} setIsNewOrder={setIsNewOrder} dataApi={dataForTab()} dataTabs={tabs} edit={edit} setEdit={setEdit}/>
        <AuthLayoutMode show={showLogin} setShow={setShowLogin}/>
        
        <InputDate
          className={clsx('form-control form-control-sm w-100 d-none',)}
          value={''}
          
          onChange={e=>{}}
          />
        
        
    </>
}

export { TableService };

