import { FC, Suspense } from "react";
import { ApiService, ApiShowError, WithChildren, removeAccents, removeCookie, setCookie, sortArrayByDependency } from "../../../../../theme/helpers";
import { DashboardWrapper } from "../../../../pages/dashboard/DashboardWrapper";
import { UpdatePage } from "../../../../../theme/partials";
import { AuthModel } from "../_models";
import { getAuth, removeAuth, setAuth } from "../AuthHelpers";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { isAuth } from "../../../../router/AppRoutes";
import { OrderManagement } from "../../../../pages/orderManagement";
import { DATA_MODE } from ".";
export async function validateToken() {
  let check = false
  const auth = await getAuth() as any
  const saveAuth = (auth: AuthModel) => {
    setAuth(auth)
    if (auth) return setAuth(auth)
    removeAuth()
  }
  // console.log('auth validateToken',auth)
  if (auth && auth.user?.id) {
    const { isAuthenticated, newAccessToken } = await isAuth()||{}
    // console.log('isAuthenticated',isAuthenticated)
    // console.log('newAccessToken',newAccessToken)
    if (isAuthenticated && newAccessToken) {
      await setCookie('token',newAccessToken,365)
    }
    if (isAuthenticated) {
      check = true
    }
  }
  // console.log('check validateToken',check)
  if(check) return null
  if(!check) {
    saveAuth(undefined as any)
    removeCookie('token')
    return redirect("/login");
  }
  // console.log('check',check)
  return null
} 
export function initPath(path: string) {
  return {
    path,
    // loader: validateToken
  }
}
export async function validateMode(path: any) {
  let check = false
  const auth = await getAuth() as any
  // console.log('auth validateMode',auth)
  if(auth && auth.user) {
    let arrVisibleto = (auth.user?.visibleto||'').split(',')
    if(arrVisibleto.includes(path)) {
      check = true
    }
    // let  {staffPermissionGroupInfo} = localStorage.getItem(DATA_MODE)|| {} as any
    // if(path == 'payment-terminal' && !staffPermissionGroupInfo.isaddpayment) {
    //   check = false
    // }
    
    // console.log('check validateMode',check)
    // console.log('check validateMode path',path)
    if (!check) {
      return redirect("/mode");
    }
  }
  // console.log('check validateMode',check)
  
  return null
}
export function initMode(path: string) {
  // console.log('initMode', path)
  return {
    path,
    loader: () => validateMode(path)
  } as any
}
export const SuspensedView: FC<WithChildren> = ({ children }) => {
    // const baseColor = getCSSVariableValue('--bs-primary')
    // TopBarProgress.config({
    //   barColors: { '0': baseColor, },
    //   barThickness: 1,
    //   shadowBlur: 5,
    // })
    // return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
    return <Suspense >{children}</Suspense>
  }
export const componentMenu: any = {
    1: {
        component: <SuspensedView children={<DashboardWrapper />} />,
    },
    
    
    
}
export const getMenubyUser = async (userid: any) => {
    return
    let menuData = [] as any
    let tempData = {
      "userId": userid,
    }
    console.log('getMenubyUser')
    try {
      let {data} = await ApiService.post(`menu/menugetbyuser`,tempData)
      data = sortArrayByDependency(data,'up_Pgm_No','pgm_No')
      data.forEach((item:any,index: number) => {
        const title = item.pgm_NM
        const code = item.pgm_No
        
        const path = removeAccents(title)
        let pages = [] as any
        let router = {
          ...item,
          ...initPath(path),
          element: componentMenu[code]? componentMenu[code].component : <UpdatePage />,
          title: title,
          icon: '/media/icons/custom/companies.svg',
        }
        if (item.children && item.children.length != 0) {
          delete router.element
          router.children = [] as any
          item.children?.forEach((i:any) => {
            let titleChild = i.pgm_NM
            let codeChild = i.pgm_No
            let pathChild = removeAccents(titleChild)
            router.children.push({
              ...i,
              ...initPath(`${pathChild}`),
              element: componentMenu[codeChild]?componentMenu[codeChild].component: <UpdatePage />,
              title: titleChild,
            })  
            if(componentMenu[codeChild]?.child) {
              router.children.push({
                ...i,
                ...initPath(`${pathChild}/:id`),
                element: componentMenu[codeChild].child,
                isHiddenMenu: true,
              })
            }
          })
        }
        if (componentMenu[code]?.child) {
          router.push({
              ...item,
              ...initPath(`${path}/:id`),
              // element: componentMenu[code].child,
              isHiddenMenu: true,
          })
        }
        pages.push(router)
        menuData = [...menuData,...pages]
      })
      // menuData = [...dashboard,...menuData]
    } catch (error) { 
      // ApiShowError(error)
      // window.location.href = '/login'

    }
    let defaultMenu = [
      {
        path: "",
        element: <Navigate to="/dashboard" />,
        isHiddenMenu: true,
      },
      {
        title: 'Dashboard',
        ...initPath("dashboard"),
        icon: '/media/icons/menu/dashboard.svg',
        element: <SuspensedView children={<DashboardWrapper />} />,
      },
      {
        title: 'Order Management',
        ...initPath("order-management"),
        icon: '/media/icons/menu/order-management.svg',
        element: <SuspensedView children={<OrderManagement />} />,
      },
    ] as any
    menuData = [...defaultMenu,...menuData]

    // console.log('menuData',menuData)
    return menuData
}