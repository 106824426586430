import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, sortWithDate } from "../../../../theme/helpers";
import { KTButton, SearchTable } from "../../../../theme/partials";
import { useAuth } from "../../../modules/auth";
import { popupMessage, popupQuestion } from "../../../modules/messages";
import { checkPaid } from "./activeOrder";
import { converDataWithCustom, getInforDataToPrint, handleClickGetItemKOT } from "./functionOrder";
import { DetailItem, HeadItemTemplate, MemberInforAndNote, ModalChooseTable, NoDataTemplate, PromotionAndSum } from "./templateOrder";
import { Trans, useTranslation } from "react-i18next";
import clsx from "clsx";
import { useStoreMode, useStorePrint } from "../../../modules/auth/core/store";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});
const badgeSearch = [{
    label:  <Trans i18nKey="orders-hub-deep.not-paid"/>,
    value: "notPaid",
},
{
    label:  <Trans i18nKey="orders-hub-deep.paid"/>,
    value: "isPaid",
},
{
    label: <Trans i18nKey="orders-hub-deep.dinner-in"/>,
    value: "dinner_in",
},
{
    label: <Trans i18nKey='orders-hub-deep.curbside'/>, //"Cubsude",
    value: "is_curbside",
},
{
    label: <Trans i18nKey='orders-hub-deep.delivery'/>,
    value: "is_delivery",
},
{
    label: <Trans i18nKey='orders-hub-deep.no-behavior'/>,
    value: "no_behavior",
},
{
    label: <Trans i18nKey='orders-hub-deep.takeout'/>,
    value: "takeout_in",
},
]
const urlApi = 'settings/customers'
const CompleteOrder = (props:any) => {
    const { t } = useTranslation();
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    let storePrint = useStorePrint((e: any)=> e)||{}
    const {dataApi,filter,setFilter, isSearch, setSearchOn,callBackReFetch, dataNotify, isClickTab, setIsClickTab} = props
    // console.log('props',props)
    // const { permission } = getLocationData()
    const navigate = useNavigate();
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(0)
    const [showTable ,setShowTable] = useState(false)
    const [note,setNote] = useState('')
    const [isloading,setIsloading] = useState(false)

    const handleCloseModalTable = () => {
        setShowTable(false)
    }
        
    useEffect(() => {
        if(dataApi ) {
            getData(dataApi)
        }
    }, [dataApi])
    useEffect(() => {
        if(props.filter) {
            console.log('filter',props.filter)
        }
    }, [props.filter])
    const getData = (data: any) => {
        if(isClickTab) {
            setDataActive(null)
            setOrderActive(-1)
            setIsClickTab(false)
        }
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
            if(item.DiningTableHistoryEntries && item.DiningTableHistoryEntries[0]) {
                item.createddate = item.DiningTableHistoryEntries[0]?.createddate
            }
        })
        data = sortWithDate(data,{sort:'createddate',order:'desc'})
        if(data.length == 0) {
            setDataActive(null)
            setOrderActive(-1)
        }
        else if(!dataActive) {
            handleClickGetItemKOT(data[0],setDataActive,setNote,setIsloading)
            setOrderActive(0)
        }
        else if(dataActive && dataNotify && dataActive.id == dataNotify?.id) {
            handleClickGetItemKOT(dataActive,setDataActive,setNote,setIsloading)
        }
        else {
            handleClickGetItemKOT(data[0],setDataActive,setNote,setIsloading)
            setOrderActive(0)
        }
        setDataQuery(data)
        
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })

    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>
    const funcCallback = async(item: any, index: number, status: string) => {
        let tempData = dataQuery.map((i: any) => i)
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            await ApiService.put(urlApi, {transactionstatus: status})
            callBackReFetch()
            tempData = tempData.filter((i: any,ind: number) => ind != index)
            setDataQuery(tempData)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }

    const checkSearch = (value: any) => {
        return filter[value]
    }
    
    const funcReorder = async(tableInfo: any) => {
        // console.log('tableInfo',tableInfo)
        setShowTable(false)

        let tempData = dataActive || {}
        tempData.tableid = tableInfo.id
        tempData.tablename = tableInfo.tablename
        tempData.tableno = tableInfo.tableno
        tempData.saletype = tableInfo.qrcodeordertype
        
        tempData.sectionid = tableInfo.sectionid
        tempData.sectionname = tableInfo.sectionname
        tempData.sectioncode = tableInfo.sectioncode
        tempData.transactionstatus = 'Open'
        tempData.HospTransactionLines = tempData.HospTransactionLines?.map((i: any) => {
            let temp = i
            temp.kitchenstatus = 'Not Sent'
            delete temp.id
            delete temp.hosptransactionid
            delete temp.transactiondate
            delete temp.transactiontime
            delete i.kotline
            delete temp.ispaid
            return temp
        })
        delete tempData.id
        delete tempData.code
        delete tempData.transactiondate
        delete tempData.transactiontime
        delete tempData.kot
        delete tempData.createdat
        delete tempData.createdby
        delete tempData.modifiedat
        delete tempData.modifiedby
        delete tempData.paid
        delete tempData.TransactionPaymentEntries
        delete tempData.nextStatus
        delete tempData.entryno
        
        // console.log('temData',tempData)
        // return
        try {
            const method = 'post'
            const urlApi = `/smarthub/hosptransactions/upsertwithdetails/`
            let {data} = await ApiService[method](urlApi, tempData)
            // callBackReFetch()
           
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }
    const [dataActive,setDataActive] = useState<any>(null)
    return <>
    <div className="row">
        <div className="col-7 ps-0">
        <form
            className="h-100 w-100"
            onSubmit={handleSubmit(async (data: any) => {
                console.log('onsubmit',data)
                return
                
            })}>
            <div className="wrap-content-card h-100">
                {isSearch && <div className="position-relative mt-4">
                    
                    <SearchTable data={dataQuery} setTableData={setDataQuery} />
                    <span className="cursor-pointer close-event-s" onClick={e => setSearchOn(false)}>{t('orders-hub-deep.cancel')}</span>
                </div>
                }
                {filter &&
                    <div className="card-header min-h-auto pt-4 d-flex gap-2">
                        {
                            badgeSearch.map((item: any) => (
                                checkSearch(item.value) && 
                                <span className="badge badge-gray d-inline-flex flex-center py-2 gap-1"><span>{item.label}</span> <i onClick={e=>{
                                    let a = JSON.parse(JSON.stringify(filter))
                                    a[item.value] = false
                                    setFilter(a)
                                }} className="bi bi-x cursor-pointer"></i></span>
                            ))
                        }
                        
                    </div>
                }
                {
                    dataQuery && dataQuery.length != 0 ?
                    <div className="card-body pb-0 pt-4">
                        {dataQuery?.map((item: any,index: number) =>{
                            return <div className="wrap-item" key={index} onClick={e=>{
                                if(orderActive == index) {
                                    return
                                }
                                setOrderActive(index)
                                handleClickGetItemKOT(item,setDataActive,setNote,setIsloading)
                            }}>
                                <HeadItemTemplate item={item} index={index} orderActive={orderActive} isTagPaid={true} checkPaid={checkPaid(item)}/>
                            </div>
                        })}
                    </div>
                    :
                    <NoDataTemplate className={'h-100 pt-4'}/>
                }
                
            </div>
            
            
            
        </form>
        </div>
        <div className="col-5 right-detail px-0">
            {dataActive && orderActive > -1 ? 
                <div className="p-3 card shadow">
                    <h6 className="py-3 d-flex flex-stack">
                        <span>{dataActive.tablename} - {dataActive.sectionname}</span>
                        <div className="d-flex gap-1">
                            {dataActive.receiptno && <div className="d-flex flex-stack"> 
                                <span>{dataActive.receiptno} </span>
                            </div>}
                            
                        </div>
                    </h6>
                    <div className={clsx({"loading-calculate":isloading})}>
                    {
                        isloading &&
                        <div className="bg-blur"><div className="loader-cal"></div></div>
                    }
                    <DetailItem className="wrap-hub-details" note={note||dataActive.message} {...dataActive} />
                    <div className="wrap-footerItem">
                        <MemberInforAndNote note={note||dataActive.message} {...dataActive} dataModal={dataActive} setDataModal={setDataActive} />
                        <PromotionAndSum paidPayment={dataActive.TransactionPaymentEntries} note={note} {...dataActive}/> 
                        
                        <div className="d-flex gap-4 p-3">
                            <button className="btn btn-sm btn-success w-100" onClick={async(e)=>{
                                let aws = await popupQuestion({title: t('orders-hub-deep.are-you-sure-you-print-receipt')})
                                if(aws) {
                                    getInforDataToPrint(dataQuery[orderActive].id, staffInfo, storePrint, 3)
                                }
                            }}><i className="bi bi-printer me-1"></i>{t('order-deep.receipt')}</button>
                            {/* <button className="btn btn-sm btn-primary w-50"><i className="bi bi-pencil me-1"></i>Edit</button> */}
                            <button onClick={e => setShowTable(true)} className="btn btn-sm btn-info w-100"><i className="bi bi-arrow-repeat me-1"></i>{t('orders-hub-deep.reorder')}</button>
                        </div>
                    </div>
                    </div>
                    
                </div>
                :
                <NoDataTemplate className={'h-100 pt-4'}/>
            }
            
        </div>
    </div>
    <ModalChooseTable 
        showTable={showTable} 
        handleCloseModal={handleCloseModalTable}
        funcCallback={funcReorder}/> 
    </>
}

export { CompleteOrder };

