import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, formatCurrent, sortByNumber } from "../../../../theme/helpers";
import { DivRoot, KTButton, SearchTable } from "../../../../theme/partials";
import { getAuth, useAuth } from "../../../modules/auth";
import { popupAlert, popupAlertBottom, popupLoading, popupMessage, popupQuestion } from "../../../modules/messages";
// import { add15MinutesToTime, converDataWithCustom } from "./functionOrder";
import { Dropdown } from "react-bootstrap";
import { CountUpTimer, callLockTable, cancelOrderInModal, checkPermissionPayment, converDataWithCustom, converErrors, createOnERP,  funcSend,  getColorTrans,  getInforDataToPrint,  notifyPayment,  timeSince, updateOnERP } from "../../orderManagement/tabs/functionOrder";
import Slider from "react-slick";
import { useStoreMode, useStorePrint } from "../../../modules/auth/core/store";
import { checkPaid } from "../../orderManagement/tabs/activeOrder";
import { ModalAddEditOrder } from "../../orderManagement/tabs/addOrder";
import { DetailItem, KITCHENSTATUS, MemberInforAndNote, ModalAddGuest, ModalChangeCustomer, ModalChooseTable, ModalPay, ModalTransferTable, NoDataTemplate, PromotionAndSum, caculPromo } from "../../orderManagement/tabs/templateOrder";
import { STATUSTABLE } from "./constant";
import { Trans, useTranslation } from "react-i18next";
import { findAndPrint } from "../../mode/print";
import Swal from "sweetalert2";
import { isArray } from "@tsparticles/engine";
import localforage from "localforage";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});

const badgeSearch = [{
    label:  <Trans i18nKey="orders-hub-deep.not-paid"/>,
    value: "notPaid",
},
{
    label:  <Trans i18nKey="orders-hub-deep.paid"/>,
    value: "isPaid",
},
{
    label: <Trans i18nKey="orders-hub-deep.dinner-in"/>,
    value: "dinner_in",
},
{
    label: <Trans i18nKey='orders-hub-deep.curbside'/>, //"Cubsude",
    value: "is_curbside",
},
{
    label: <Trans i18nKey='orders-hub-deep.delivery'/>,
    value: "is_delivery",
},
{
    label: <Trans i18nKey='orders-hub-deep.no-behavior'/>,
    value: "no_behavior",
},
{
    label: <Trans i18nKey='orders-hub-deep.takeout'/>,
    value: "takeout_in",
},
]
const urlApi = 'settings/customers'
const MBar = (props:any) => {
    const { t } = useTranslation()
    const {dataNotify, transColors, dataApi,filter,setFilter, isSearch, setSearchOn, callBackReFetch, edit, setEdit, dataTabs, refetchCurrent, refetchTable, isNewOrder, setIsNewOrder} = props
    const { currentUser } = useAuth() as any
    // const { permission } = getLocationData()
    const [dataTable,setDataTable] = useState<any>([])
    // const [selected, setSelected] = useState<any>([])
    // const [selectedTranfer, setSelectedTranfer] = useState<any>([])
    const [tab, setTab] = useState<any>({})
    const [showTable,setShowTable] = useState(false)
    const [showTransferTable,setShowTransferTable] = useState(false)
    
    const [transferTable,setTransferTable] = useState<any>(null)
    const [showPayTemplate,setShowPayTemplate] = useState(false)
    const [isCallLock,setIsCallLock] = useState(false)
    
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}
    let storePrint = useStorePrint((e: any)=> e)||{}
    
  
    useEffect(() => {
        if(dataNotify) {
            const resetTableView = () => {
                setOrderActive(-1)
                setEdit(false)
                refetchOrderService()
                setShowTable(false)
                setShowTransferTable(false)
            }
            let modal_payment = document.getElementById('modal_payment')
            const printEinvoice = async() => {
                let infoSetupPrint = await localforage.getItem("merchantsData")||{} as any
                let {receiptsetups} = infoSetupPrint||{}
                let username = (await getAuth() as any).user?.username
                if(receiptsetups.closedcheckreceipt == 'printInvoice' && username == dataNotify.username) {
                getInforDataToPrint(dataNotify?.id, staffInfo, storePrint,3)
                }
            }
            if(dataNotify.einvoice) {
                printEinvoice()
            }
            if(dataNotify.islocked && dataNotify.notifycode == '200-5000' && dataNotify.tableno == dataEdit.tableno) {
                if(showPayTemplate || modal_payment) {
                    setShowPayTemplate(false)
                }
            }
            if(dataNotify.code == dataEdit.code && dataEdit.code && dataNotify.notifycode == '200-3000' && (!dataNotify.lockinfo || dataNotify.transactionstatus == 'Completed')) {//reset table
                if(dataNotify.tableno == dataEdit.tableno ) {
                    
                    if(transferTable) {
                        cancelTableOccupied && cancelTableOccupied({id: transferTable?.tableid})
                    }
                    resetTableView()
                }
            }
            else if(!dataNotify.islocked && dataNotify.notifycode == '200-5000') {
                // console.log('transferTable',transferTable)
                if(dataNotify.tableno == transferTable?.tableno ) {
                    // console.log('dataEdit',dataEdit)
                    if(dataEdit.lockinfo) {
                        callLockTable && callLockTable({
                            transactionid: dataEdit?.id,
                            lockinfo: null,
                        },setIsCallLock)
                        resetTableView()
                        setShowPayTemplate(false)
                    }
                }
                else if(dataNotify.tableno == dataEdit.tableno && transferTable) {
                    // console.log('333333333333')
                    cancelTableOccupied && cancelTableOccupied({id: transferTable?.tableid})
                    resetTableView()
                }
            }
        }
        
    },[dataNotify])
    
    const refetchPayOrder = () => {
        refetchTable()
        refetchOrderService()
    }
    const refetchTableFunc = () => {
        refetchTable()
        setIsNewOrder(false)

    }
    const handleCloseModal = async(isCompleted?: any) => {
        setShowTable(false)
        // setShowTransferTable(false)
        if(dataEdit.lockinfo) {
            callLockTable({
                transactionid: dataEdit?.id,
                lockinfo: null,
            },setIsCallLock)
        }
        
       
    }
    const cancelTableOccupied = async(dataOccupy: any) => {
        try {
            // const urlApi = `smarthub/hosptransactions/canceltableoccupied`
            const urlApi = `smarthub/hospdiningtables/unlock/${dataOccupy.id}`//tableid
            // let tempData = {
            //     "diningtablehistoryentryid": dataOccupy?.id,
            // }
            let {data} = await ApiService.post(urlApi)
            setDataOccupytable({})
            // setDataOccupytableFrom({})
        } catch (error) { ApiShowError(error,'cancelTableOccupied',staffInfo) }
    }
    const handleCloseModalTransfer = async(e: any,isCompleted?: any) => {
        // console.log('handleCloseModal',e,isCompleted)
        // console.log('transferTable',transferTable)
        if(transferTable && transferTable.id) {
            callLockTable({
                transactionid: transferTable.Trans[0]?.id,
                lockinfo: null,
            },setIsCallLock)
        }
        setTransferTable(null)
        // setShowTable(false)
        setShowTransferTable(false)
        // console.log('dataOccupytable',dataOccupytable)
        if(dataOccupytable && dataOccupytable.id && !isCompleted ){ //&& (!isCompleted && typeof isCompleted == "boolean")
            cancelTableOccupied(dataOccupytable)
        }
        if(dataEdit && dataEdit.lockinfo) {
            callLockTable({
                transactionid: dataEdit?.id,
                lockinfo: null,
            },setIsCallLock)
        }
    }
    const formatDataTransfer = (data: any,arrIdTrans?: any ) => {//arrIdTrans: default
        // console.log('formatDataTransfer',data)
        let hospDefaultLineno = [] as any
        if(arrIdTrans) {
            hospDefaultLineno = data?.HospTransactionLines?.filter((i: any) => arrIdTrans.includes(i.id)).map((i: any) => i.lineno)
        }
        let HospTransactionLines = data?.HospTransactionLines?.map((i: any,index: number) =>{
            // console.log('hospDefaultLineno',hospDefaultLineno)
            let kitchenstatus = i.kitchenstatus
            let lineno = (index+1)*1000
            let istransfered = undefined  as any

            if(!arrIdTrans) {
                lineno = i.lineno
            }
            else {
                if(arrIdTrans.includes(i.id)) {
                    lineno = i.lineno
                }
                else if(hospDefaultLineno.length != 0) {
                    istransfered = true
                    let max = Math.max(...hospDefaultLineno) 
                    // console.log('max',max)
                    lineno = max+1000
                    kitchenstatus = KITCHENSTATUS.sent
                    hospDefaultLineno.push(lineno)
                }
                else {
                    kitchenstatus = KITCHENSTATUS.sent
                }
            }
            if(i.isCreate) {
                i.id = undefined
            }
            let temp = {...i,hosptransactionid: data.id, lineno: lineno,kitchenstatus:kitchenstatus,istransfered: istransfered}
            // console.log('lineno',lineno)
            
            if(temp.customizations && temp.customizations.length!=0) {
                temp.customizations = temp.customizations.map((cus: any,ind: any) =>({...cus,lineno: (lineno+ind+1)}))
            }
            // delete temp.HospItem
            // console.log('temp',temp)
            return temp

        })
        // console.log('HospTransactionLines',HospTransactionLines)
        let dataAmount = caculPromo(HospTransactionLines, dataEdit)
        // console.log('dataAmount',dataAmount)
        let discountAmount = +dataAmount?.linediscount||0 + +data?.totaldiscount||0
        
        // let discountlinesErp = [] as any
        // HospTransactionLines.forEach((i: any) => {
        //     let record = discountlines?.find((d: any) =>d.LineNoExt == i.lineno)
        //     if(record) {
        //         discountlinesErp.push(record)
        //     }
        //     if(i.customizations && i.customizations.length != 0) {
        //         i.customizations.forEach((cus: any) => {
        //             let recordCus = discountlines?.find((d: any) =>d.LineNoExt == i.lineno)
        //             if(recordCus) {
        //                 discountlinesErp.push(recordCus)
        //             }
        //         })
        //     }
        // })
        delete data.HistoryEntries
        delete data.Trans
        delete data.DiningTableHistoryEntries
        delete data.discountlines
        let tempData = {
            ...data,
            "id": data.id,
            "merchantid": staffInfo.merchantid,
            // "tableid": data.tableid,
            // "tableno": data.tableno,
            // "tablename": data.tablename,
            // "sectionid": data.sectionid,
            // "sectioncode": data.sectioncode,
            // "sectionname": data.sectionname,
            // "transactiontype": data.transactiontype,
            // "transactionstatus": data.transactionstatus,
            // "kitchenstatus": data.kitchenstatus,
            "staffcode": staffInfo.code,
            "staffreceiptname": staffInfo.nameonreceipt,
            // "noofitems": 1,
            // "noofitemlines": 1,
            "netamount": dataAmount.netamount,
            "taxamount": dataAmount.taxamount,
            "grossamount": dataAmount.grossamount,
            totallinediscount: +dataAmount?.linediscount, //sum discount lines
            discountamount: discountAmount,
            "totaldiscount": +data?.totaldiscount||0,//+thêm chương trình tên là totaldiscount
            "code": data.code,
            "customerinfo": data.customerinfo||{
                name: "Guest"
            },
            "message": data.message,
            // diningtablehistoryentryid: dataOccupytable?.id || undefined,
            "saletype":data.saletype,// || dataOccupytable?.qrcodeordertype || undefined,
            "hospitalitytype": data.hospitalitytype,// || dataOccupytable?.salestype||undefined,
            HospTransactionLines: HospTransactionLines,
            // discountlines: discountlinesErp,
            kitchenstatus:  data.id ? (data.kitchenstatus|| 'Not Sent') : 'Active',
            entryno: undefined,
        }
        return tempData
    }
    const changeCustomer = (item: any) => {
        setDataEdit(item)
        setIsShowCustomerInfo(true)
    }
    const findItemToPrintTableold = (allData: any, data: any,idsDefault: any, ) => {
        let tempId = data.map((i: any) => i.id)//list id items table
        let ids = idsDefault.filter((i: any)=> !tempId.includes(i))//lấy ra items đã transfer
        let a = allData.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.notSent && ids.includes(i.id))
        return a
        
    }
    const findItemToPrintTransfer = (allData: any, data: any,idsDefault: any, ) => {
        let tempId = data.map((i: any) => i.id)//list id items table
        // console.log('tempId',tempId)
        let ids = tempId.filter((i: any)=> !idsDefault.includes(i))//lấy ra items được transfer
        // console.log('ids',ids)
        let a = allData.filter((i: any) => ids.includes(i.id))
        // console.log('a',a)
        return a
    }
  
    const funcTransTable = async(props: any) => {
        let {dataTable,dataTransferTable, idTableDefault, idTransferTableDefault} = props
        console.log('props',props)
        // let discountLines = (dataTable.discountlines||[]).concat(dataTransferTable.discountlines||[])
        let HospLines = dataTable.HospTransactionLines.filter((i: any) => !i.isClone)// filter data line
        let HospTransferLines = dataTransferTable.HospTransactionLines.filter((i: any) => !i.isClone)// filter data line
        let allItems = HospLines.concat(HospTransferLines)
        let LinesExtend =  HospTransferLines.filter((i: any) => i.isCreate)
        // console.log('allItems',allItems)
        dataTable.HospTransactionLines = HospLines
        dataTransferTable.HospTransactionLines = HospTransferLines
        let tempData = {
            fromtransaction: formatDataTransfer(dataTable),
            totransaction: formatDataTransfer(dataTransferTable,idTransferTableDefault||[]),
            "staffcode": staffInfo.code,
            "staffname": staffInfo.fullname,
            
        } as any
        
        let itemLineTransfer = findItemToPrintTransfer(allItems,HospTransferLines,idTransferTableDefault)
        // console.log('itemLineTransfer',itemLineTransfer)
        let itemsTransferKotNotSent = itemLineTransfer?.filter((i: any) => i.kitchenstatus == KITCHENSTATUS.notSent)
        let idsKotNotSent = itemsTransferKotNotSent.filter((i: any) => i.id).map((i: any) => i.id)
        // let itemsTranferSent = itemLineTransfer?.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.notSent)
        if(idsKotNotSent.length != 0) {
            tempData.changekotline = {
                kitchenstatus: KITCHENSTATUS.sent,
                ids: idsKotNotSent
            }
        }
        
        
        try {
            const method = 'put'
            const urlApi = `/smarthub/hosptransactions/transfer`
            popupLoading()
            let {data} = await ApiService[method](urlApi, tempData)
            if(data) {
                let codeTo = data.upsertedTransactionTo?.code
                let textTitle = dataTransferTable.id?'GỘP BÀN':'CHUYỂN BÀN'
                let textNoteAdd = dataTransferTable.id ? `(#${codeTo})` : '' 
                let tempPrint = {
                    ...dataTable,
                    dataInfo: {type:'transfer',title: textTitle, description: `${dataTable.tablename} -> ${dataTransferTable.tablename} ${textNoteAdd}`,staffInfo: staffInfo}
                }
                let dataLineTransfer = findItemToPrintTableold(allItems,HospLines,idTableDefault)
                if(LinesExtend.length != 0) {
                    dataLineTransfer = LinesExtend.concat(dataLineTransfer)
                }
                findAndPrint(dataLineTransfer,tempPrint,{
                    PrinterList: storePrint.PrinterList,
                    setPrinterlist: storePrint.setPrinterlist,
                    staffInfo: staffInfo,
                },textTitle)//print thông báo món bàn củ đã chuyển
            }
            // console.log('itemsTransferKotNotSent',itemsTransferKotNotSent)
            if(itemsTransferKotNotSent.length != 0){
                //--------------------------------------------------------------------
                let dataPrint = {
                    ...(data?.upsertedTransactionTo || dataTransferTable),
                    dataInfo: {
                        staffInfo: staffInfo
                    }
                }
                findAndPrint(itemsTransferKotNotSent,
                    dataPrint,{
                    PrinterList: storePrint.PrinterList,
                    setPrinterlist: storePrint.setPrinterlist,
                    staffInfo: staffInfo,
                })//print item chuyển bàn tới
            }
            

            handleCloseModalTransfer({},true)
            refetchOrderService()
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error: any) { 
            if(error) {
                // console.log('errors',error)
                let mess = error.response?.data?.errorMessage
                let errors = converErrors([mess]) as any
                const callFunc = () => {
                    handleCloseModalTransfer({},true)
                    refetchOrderService()
                    if(!tempData.totransaction?.id) {
                        funcUnlockOrder(tempData.totransaction)
                    }
                    else {
                        callLockTable({
                            transactionid: tempData.totransaction?.id,
                            lockinfo: null,
                        },setIsCallLock)
                    }
                }
                popupMessage({icon: 'info',callFunc:callFunc,  description: errors.join('; ')})
                
                
            }
            // ApiShowError(error)
        }

    }
    const [dataOccupytable,setDataOccupytable] = useState<any>({})
    const funcLockOccupytable = async(dataItem: any, setDataOccupytable: any) =>  {
        // const urlApi = `smarthub/hosptransactions/occupytable`
        try {
            const urlApi = `smarthub/hospdiningtables/lock/${dataItem.id}`

            // let tempData = {
            //     "tableid": dataItem.id,
            //     "tableno": dataItem.tableno,
            //     "tablename": dataItem.tablename,
            //     "sectionid": dataItem.sectionid,
            //     "sectioncode": dataItem.sectioncode,
            //     "salestype": dataItem.salestype || dataItem.hospitalitytype,
            // }
            // console.log('tempData',tempData)
            let {data} = await ApiService.post(urlApi)
            setDataOccupytable({...dataItem})
        } catch (error) {
            
        }
        
    }
    const funcChooseTable = async(dataItem: any) => {
        setShowTable(false)
        // console.log('funcChooseTable',dataItem)
        if(dataItem.isTransfer) {
            if(dataItem && dataItem.HistoryEntries && dataItem.HistoryEntries.length != 0 && dataItem.HistoryEntries[0]?.type != STATUSTABLE.free) {
                let asw = await popupQuestion({
                    title:`${t('table-service-deep.change-table')} - ${t('table-service-deep.check')} #${dataEdit.code}, ${t('table-service-deep.table')} ${dataEdit.tableno}`,
                    description:`${t('table-service-deep.move-check')} #${dataEdit.code} ${t('table-service-deep.to-table')} ${dataItem.tableno}`
                })
                if(asw) {
                    setShowTransferTable(true)
                    setTransferTable(dataItem)
                    callLockTable({
                        transactionid: dataItem.Trans[0]?.id,
                        lockinfo: {
                            staffcode: staffInfo.code,
                        },
                    },setIsCallLock)
                }
            }
            else {
                try {
                    funcLockOccupytable(dataItem,setDataOccupytable)
                } catch (error) { ApiShowError(error,'funcLockOccupytable',staffInfo) }
                // await createHospTrans(data,setTransferTable)
                // refetchTable()
                let tempData = {...dataItem,tableid: dataItem.id,id: undefined}
                // console.log('staffInfo.Merchant',staffInfo.Merchant)
                setTransferTable({
                    ...tempData,
                    "merchantkey": staffInfo.Merchant?.merchantkey,
                    "merchantcode": staffInfo.Merchant?.merchantcode,
                    "merchantname": staffInfo.Merchant?.merchantname,
                    "merchantid": staffInfo.merchantid,
                    "storeno": staffInfo.Merchant?.storecode,
                    // transactionstatus: 'Open',
                    hospitalitytype: dataItem.salestype,
                    saletype: dataItem.qrcodeordertype,
                    transactionstype: 'Sales',
                })
                setShowTransferTable(true)
    
            }
        }
        
    }
    const [clickTable,setClickTable] = useState<any>(null)
    useEffect(() => {
        // console.log('bbbbbbbbbbbbbb',dataTabs)
        // console.log('orderActive',orderActive)

        if(dataTabs && dataTabs.length != 0) {
            // console.log('ádasdasdsad',dataEdit)
            // console.log('orderActive',orderActive)
            if(orderActive != -1 && dataEdit) {
                // console.log('DataQuery',dataQuery)
                // console.log('dataEdit',dataEdit)
                
                // console.log('dataTabs',dataTabs)
                let item = null as any
                dataTabs.forEach((i: any) => {
                    if(item) return
                    let DiningTables = i.DiningTables
                    // console.log('DiningTables',DiningTables)

                    if(DiningTables) {
                        item = DiningTables.find((table: any) => table.id == (dataEdit.tableid))
                    }
                })
                item = item || {}
                // console.log('item clickTable',clickTable)
                // console.log('item dataNotify',dataNotify)
                // console.log('item dataEdit',dataEdit)
                if(!clickTable && dataNotify?.tableno == dataEdit?.tableno) {
                    getDetailOrder(dataEdit?.id, item)
                }
            }
            // console.log('tab',tab)
            if(!tab.id) {
                setTab(dataTabs[0])
            }
            else {
                let tempTab = dataTabs.find((i: any) => i.id == tab.id)
                setTab(tempTab)
            }
        }

    },[dataTabs])
    // const { data: dataHosTable, isSuccess: successTable } = useDiningTablesWithQuery(tab && tab.id && `?sectionid=${tab.id}&merchantid=${tab.merchantid}`)
    // const { data: dataHosTable, isSuccess: successTable } = useDiningTablesWithQuery(`?merchantid=6cb83bd2-19d6-4db9-b903-cd95c28f91a3&expand=merchant,section`)
    // useEffect(() => {
    //     if(dataHosTable) {
    //         setDataTable(sortByNumber(dataHosTable||[],'tableno'))
    //     }
    // },[dataHosTable])
 
    const navigate = useNavigate();
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(-1)
    const [viewTableOrder ,setViewTableOrder] = useState<any>(false)
    const [dataEdit ,setDataEdit] = useState<any>({})
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}

    // console.log('staffInfo',staffInfo)
    // const [ePosDev,setEPosDev] = useState(new (window as any).epson.ePOSDevice())
    useEffect(() => {
        if(dataApi) {
            // console.log('dataApi',dataApi)
            getData(dataApi)
            // setEPosDev()
        }
    }, [dataApi])
    useEffect(() => {
        // console.log('dataEdit',dataEdit)
    }, [dataEdit])
    
    useEffect(() => {
        if(isNewOrder) {
            setDataEdit({})
        }
    }, [isNewOrder])
    useEffect(() => {
        if(props.filter) {
            // console.log('filter',props.filter)
        }
    }, [props.filter])
    const getData = (data: any) => {
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
        })
        // console.log('getData',data)
        
        setDataQuery(sortByNumber(data,'tableno'))
    
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })

    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>
    const refetchOrderService = () => {
        setDataEdit({})
        setViewTableOrder(false)
        setOrderActive(-1)
        refetchTable && refetchTable()
    }
    
    const completeOrder = async(item: any,) => {
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            await ApiService.put(urlApi, {transactionstatus: 'Completed'})
            refetchOrderService()
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }

    const checkSearch = (value: any) => {
        return filter[value]
    }
    const toggleRef = useRef<HTMLButtonElement | null>(null)
    const createHospTrans = async(tableInfo: any,setData: any, item?: any) => {
        
        // console.log('tableInfo',tableInfo)
        let tempData = {
            "hospitalitytype": tableInfo?.salestype||undefined,
            "saletype": tableInfo?.qrcodeordertype,
            "merchantkey": staffInfo.Merchant?.merchantkey,
            "merchantcode": staffInfo.Merchant?.merchantcode,
            "merchantname": staffInfo.Merchant?.merchantname,
            "merchantid": staffInfo.merchantid,
            "storeno": staffInfo.Merchant?.storecode,
            "tableid": item?.tableid?item.tableid:tableInfo?.id,
            "tableno": item?.tableno?item.tableno:tableInfo?.tableno,
            "tablename": item?.tablename?item.tablename:tableInfo?.tablename,
            "sectionid": tableInfo?.sectionid,
            "sectioncode": tableInfo?.sectioncode,
            "sectionname": tableInfo?.sectionname,
            "transactiontype": "Sales",
            "transactionstatus": "Active",
            "kitchenstatus": "Not Sent",
            
            "staffcode": staffInfo.code,
            "staffreceiptname": staffInfo.nameonreceipt,
            // "noofitems": 1,
            // "noofitemlines": 1,
            // "linediscount": 0,
            // "totaldiscount": 0,
            "customerinfo": {name: 'Guest'},
            "HospTransactionLines": []
        }
        let urlApi = `smarthub/hosptransactions/create`
        try {
            let {data} = await ApiService.post(urlApi, tempData)
            setEdit(true)
            setViewTableOrder(false)
            
            if(data && data.upsertedHospTransaction) {
                let temp = data.upsertedHospTransaction
                setData(temp)
                callLockTable({
                    transactionid: temp?.id,
                    lockinfo: {
                        staffcode: staffInfo.code,
                    },
                },setIsCallLock)
            }
        } catch (error) { ApiShowError(error, 'createHospTrans', staffInfo) }

    }
    
    useEffect(()=>{
        if(dataEdit?.id) {
            let a = toggleRef.current?.className
            // console.log('toggleRef',a)
            if(a?.indexOf('collapsed') == -1) {
                // console.log('click toggle')
                toggleRef.current?.click()
            }
        }
    },[orderActive])
    const [infoTableModal,setInfoTableModal] = useState<any>({})
 
    // const [dataDetail,setDataDetail] = useState<any>(null)
    // const [note,setNote] = useState('')
    const [listDataSlider,setListDataSlider] = useState<any>([])
    useEffect(()=>{
        // let a = 
        setListDataSlider(listDataSlider.map((i: any) => {
            if(i.code == dataEdit.code) {
                return dataEdit
            }
            return i
        }))
    },[dataEdit])
    const settings = {
        className: "center",
        slidesToShow: 1,
        dots: true, 
        swipeToSlide: true,
    };
    const getDetailOrder = async(id: any,item: any) => {
        // console.log('item',item)
        let islocked = item.islocked
        if(id) {
            // let data =  await handleClickGetItemKOT({id:id},setDataEdit,setNote)
            // console.log('item',item)
            let tableid = item.tableid || item.id
            // console.log('tableid',tableid)
            try {
                let urlApi = `smarthub/hosptransactions/${staffInfo.merchantid}/allday?tableid=${tableid}&expand=hosptransactionlines,kots,kotlines,DiningTableHistoryEntries,hospitem,TransactionPaymentEntries`
                let {data} = await ApiService.get(urlApi)
                // console.log('data',data)
                setTimeout(()=>{
                    setClickTable(false)
                },500)
                if(data && data.length != 0) {
                    let sortData = sortByNumber(data,'code','DESC').map((i: any) =>({...i,islocked: islocked}))
                    setListDataSlider(sortData)
                    if(dataEdit.id) {
                        let temp = sortData.find((i: any) => i.id == dataEdit.id)||sortData[0]
                        setDataEdit(temp)
                        if(temp.lockinfo && temp.lockinfo.staffcode != staffInfo.code ) {
                            refetchOrderService()
                            return
                        }
                    }
                    else {
                        setDataEdit(sortData[0])
                    }
                    //==========
                    let a = toggleRef.current?.className
                    if(a?.indexOf('collapsed') == -1) {
                        toggleRef.current?.click()
                    }
                }
                else {
                    // setDataEdit({})
                    setOrderActive(-1)
                    setEdit(false)
                    refetchOrderService()
                } 
            } catch (error) {
                ApiShowError(error, 'getDetailOrder', staffInfo)
            }
            
        }
          
        
    }
    
    const funcUpsertOrderERP = async(item: any) => {
        let aws = await popupQuestion({
            title: t(`table-service-deep.${item.receiptno?'update':'create'}--order-erp-question`),
            description:t(`table-service-deep.${item.receiptno?'update':'create'}--order-erp-question-description`),
        })
        if(aws) {
            popupLoading()
            try {
                const urlApi = `lscentral/hosporders/manual/upserthosporder`
                let tempData = {
                    "transactionid": item?.id,
                    "staffcode": staffInfo.code,
                    "staffname": staffInfo.fullname,
                }
                let {data} = await ApiService.post(urlApi, tempData)
                if(data.errors && data.errors.length != 0) {
                    let errors = converErrors(data.errors) as any
                    popupMessage({icon: 'info', description: errors.join('; ')})
                }
                else {
                    Swal.close()
                }
            } catch (error) { ApiShowError(error, 'funcUpsertOrderERP', staffInfo) }
        }
        
    }
    const funcSyncOrderERP = async(item: any) => {
        let aws = await popupQuestion({
            title:t('table-service-deep.sync--order-erp-question'),
            description:t('table-service-deep.sync--order-erp-question-description'),
        })
        if(aws) {
            popupLoading()
            try {
                const urlApi = `lscentral/hosptransactions/manual/updatehosptransactionstatus`
                let tempData = {
                    // "transactionid": item?.id,
                    "receiptno": item.receiptno,
                    "storeno": item.storeno,
                    "staffcode": staffInfo.code,
                    "staffname": staffInfo.fullname,
                }
                let {data} = await ApiService.post(urlApi, tempData)
                refetchOrderService()
                Swal.close()
            } catch (error) { ApiShowError(error, 'funcSyncOrderERP', staffInfo) }
        }
        
    }
    const funcUnlockOrder = async(item: any) => {
        try {
            const urlApi = `smarthub/hospdiningtables/unlock/${item.tableid}`
            let {data} = await ApiService.post(urlApi,{
                "staffcode": staffInfo.code,
                "staffname": staffInfo.fullname,
            })
            // refetchOrderService()
            Swal.close()
        } catch (error) { ApiShowError(error,'funcUnlockOrder',staffInfo) }
        
    }
    
    const funcClearTable = (item: any) => {
        const callPrint = () => {
            let voidLines = item.HospTransactionLines?.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.notSent)
            if(voidLines.length != 0) {
                let tempData = {
                    funcName: `funcClearTable`,
                    error:  `call in in function funcClearTable`,
                    staffcode: staffInfo?.code,
                    time: new Date(),
                }
                ApiService.post(`smarthub/logs/write`, tempData)

                findAndPrint(voidLines,{
                    ...item,
                    dataInfo: {type:'voided',title: 'HỦY MÓN', staffInfo: staffInfo}

                }, {
                    PrinterList: storePrint.PrinterList,
                    setPrinterlist: storePrint.setPrinterlist,
                    staffInfo: staffInfo,
                },'HỦY MÓN')
            }
        }
        cancelOrderInModal(item, refetchOrderService,callPrint,staffInfo)
    }
    // const [dataOccupytableFrom,setDataOccupytableFrom] = useState<any>({})
    const funcTransferTable = async(item: any) => {
        setDataEdit(item)
        setShowTable(true)
        // setDataOccupytableFrom(item)
        // funcLockOccupytable(item,setDataOccupytableFrom)
        callLockTable({
            transactionid: item.id,
            lockinfo: {
                staffcode: staffInfo.code,
            },
        },setIsCallLock)
    }
    const renderItem = (item: any) => {

        return <div className="p-0 card shadow position-sticky-custom py-3">
        <h6 className="d-flex flex-stack px-3">
            <div>
                {/* <span className="cursor-pointer" onClick={ e=> {
                refetchOrderService()
                }}><i className="me-3 fs-3 fw-bold text-black bi bi-arrow-left"></i></span> */}
                <span>{item.tablename} - {item.sectionname}</span>
            </div>
            <span className="d-flex gap-2 flex-center">
                {item.receiptno && 
                    <span className="">{item.receiptno} </span>
                }
                {actionTitle(item)} 
            </span>
        </h6>
        
        <DetailItem {...item} note={item.message} />
        <div className="wrap-footerItem">
            {item.islocked && <div className="px-3 text-danger fs-2 text-center fw-bold">{t('order-deep.use-on-pos')}</div>}
            <MemberInforAndNote note={item.message} {...item} setDataModal={setDataEdit} dataModal={item} />
            <PromotionAndSum paidPayment={item.TransactionPaymentEntries} {...item}/>
            <div className="d-flex gap-4 mt-3 px-3">
                
                {/* <button className={clsx("btn btn-sm btn-success w-100", {'disabled': item.islocked})} onClick={e=>{
                        let preifix = ''
                        prePrintReceipt(item.HospTransactionLines,item,{
                            PrinterList: storePrint.PrinterList,
                            setPrinterlist: storePrint.setPrinterlist,
                            staffInfo: staffInfo,
                        },preifix)
                        popupMessage({ icon: 'success', autoClose: true })
    
                    }}><i className="bi bi-printer me-1"></i>{t('order-deep.pre-print')}</button> */}
                    {
                    ( item.HospTransactionLines && item.HospTransactionLines.length != 0) ?
                        <button className={clsx("btn btn-sm btn-success w-100 px-2", {'disabled': item.islocked})} onClick={e=>{
                            funcUpsertOrderERP(item)
        
                        }}><i className="bi bi-recycle me-1"></i>{t(`order-deep.${item.receiptno?'update':'create'}-order`)}</button> 
                        :<></>
                        // <button className={clsx("btn btn-sm btn-success w-100", {'disabled': item.islocked})} onClick={e=>{
                        //     let preifix = ''
                        //     prePrintReceipt(item.HospTransactionLines,item,{
                        //         PrinterList: storePrint.PrinterList,
                        //         setPrinterlist: storePrint.setPrinterlist,
                        //         staffInfo: staffInfo,
                        //     },preifix)
                        //     popupMessage({ icon: 'success', autoClose: true })
        
                        // }}><i className="bi bi-printer me-1"></i>{t('order-deep.pre-print')}</button> 
                    }
                
                {
                staffPermissionGroupInfo.isopensalepos && !checkPaid(item).isPaid ?
                    <button onClick={e=>{
                        setEdit(true)
                        setDataEdit(item)
                        callLockTable({
                            transactionid: item.id,
                            lockinfo: {
                                staffcode: staffInfo.code,
                            },
                        },setIsCallLock)
                    }} className={clsx("btn btn-sm btn-primary w-100 px-2", {'disabled': item.islocked})}><i className="bi bi-pencil me-1"></i>{t('table-service-deep.update')}</button>
                    :
                    <></>

                }
                {
                checkPermissionPayment(currentUser,staffPermissionGroupInfo) && !checkPaid(item).isPaid && item.grossamount != 0 &&
                    <button onClick={e => {
                        callLockTable({
                            transactionid: item?.id,
                            lockinfo: {
                                staffcode: staffInfo.code,
                            },
                        },setIsCallLock)
                        setDataEdit(item)
                        setShowPayTemplate(true)
                    }} className="btn btn-sm btn-info w-100 px-2"><i className="bi bi-printer me-1"></i>{t('table-service-deep.pay')} {formatCurrent(checkPaid(item).amount)}</button>
                }
            </div>
        </div>
        
        
        
    </div>
    }
    const [noofcovers,setNoofcovers] = useState<any>(null)
    useEffect(()=>{
        noofcovers && refetchTable()
    },[noofcovers])
    const [isShowGuest,setIsShowGuest] = useState<any>(false)
    const [isShowCustomerInfo,setIsShowCustomerInfo] = useState<any>(false)
    
    const handleClickAddGuest = (item: any) => {
        setDataEdit(item)
        setIsShowGuest(true)
    }
    const actionTitle = (item: any) => {

        return <div className="d-flex flex-center gap-5">
        <Dropdown>
            <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
            {/* <i className="bi bi-list-ul" /> */}
            <i className="bi bi-three-dots-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg-light-primary fw-bold fs-6 w-250px p-2' >
                
                <Dropdown.Item as='div' className={clsx('menu-item d-none')} >
                    <span onClick={e=>handleClickAddGuest(item)} className='text-black'>
                      <span className="menu-link d-flex gap-2 align-items-center"><i className="bi bi-people-fill text-reset"></i>{t('order-deep.add-guest')}</span>
                    </span>
                </Dropdown.Item>
                {
                    staffPermissionGroupInfo.istransferorders && <Dropdown.Item as='div' className={clsx('menu-item',{'disabled text-gray-500': item.islocked || !item.receiptno || !item.HospTransactionLines?.find((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)})} >
                    <span className={clsx('text-black',{'disabled text-gray-500': item.islocked || !item.receiptno || !item.HospTransactionLines?.find((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)})} onClick={e=>funcTransferTable(item)}> 
                        <span className="menu-link d-flex gap-2 align-items-center"><i className="bi bi-arrow-left-right text-reset"></i>{t('table-service-deep.transfer-to-table')}</span>
                    </span>
                </Dropdown.Item>
                }
                
                
                
               
                
                {
                   (item.receiptno && item.HospTransactionLines && item.HospTransactionLines.length != 0) &&
                    <Dropdown.Item as='div' className={clsx('menu-item',{'disabled': item.islocked})} >
                        <span className={clsx('text-black',{'disabled text-gray-500': item.islocked})} onClick={e=>funcSyncOrderERP(item)}> 
                        <span className="menu-link d-flex gap-2 align-items-center"><i className="bi bi-check2-circle text-reset"></i>{t('order-deep.sync-order')}</span>
                        </span>
                    </Dropdown.Item>
                }
                {
                   (staffPermissionGroupInfo.ismanagerprivileges) &&
                    <Dropdown.Item as='div' className={clsx('menu-item',{'disabled': !item.islocked})} >
                        <span className={clsx('text-black',{'disabled text-gray-500': !item.islocked})} onClick={e=>funcUnlockOrder(item)}> 
                        <span className="menu-link d-flex gap-2 align-items-center"><i className="bi bi-unlock text-reset"></i>{t('order-deep.unlock-order')}</span>
                        </span>
                    </Dropdown.Item>
                }
                {
                   ( staffPermissionGroupInfo.isvoidtransaction) &&
                    <Dropdown.Item as='div' className={clsx('menu-item', {'disabled': item.islocked})} >
                        <span className={clsx('text-black',{'disabled text-gray-500': item.islocked})} onClick={e=>funcClearTable(item)}> 
                            <span className="menu-link d-flex gap-2 align-items-center"><i className="bi bi-trash3 text-reset"></i>{t('order-deep.void-order')}</span>
                        </span>
                    </Dropdown.Item>
                }
                
            </Dropdown.Menu>
        </Dropdown>
    </div>
    }
    
    const toolbarAction = <div className="d-flex flex-center gap-3">
        <button className={clsx("btn btn-icon btn-sm cursor-pointer",{'active btn-light-primary': orderActive != -1,'bg-light-primary text-primary': orderActive == -1})} onClick={e=> {
            refetchOrderService()
        }} ><i className="bi bi-layout-text-sidebar text-reset"></i></button>
        <button className='btn btn-light-primary btn-active-primary btn-sm btn-icon collapsed' data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" ref={toggleRef} onClick={e=> {
            let a = e.target as any
            if(a.className?.indexOf('collapsed') == -1) {
            // console.log('e',e)

                setOrderActive(-1)
                setViewTableOrder(false)
            }
        }} aria-expanded="false" aria-controls="collapseWidthExample"><i className="bi bi-list-ul" /></button>
        
    
    </div>
    const templateItem = (item: any, index: number) => {
        let badge = checkPaid(item,'PaymentEntries') as any
        // console.log('item',item)
        return <div className="wrap-item" key={index} >
        <div onClick={e=>{
            if(item.islocked || ((item.receiptno || !item.grossamount) && getColorTrans(item,transColors) == 'orange' && item.lockinfo?.staffcode != staffInfo.code)) {
                return
            }
            setOrderActive(-1)
            setViewTableOrder(true)
            
            getDetailOrder(item.id,item)
        }} className={clsx('item bg-white mb-3 p-2 border border-2 d-flex',{'active bg-primary bg-opacity-10 border-primary': index == orderActive},{
                        'bg-orange text-white border-orange border-solid ': (item.receiptno || !item.grossamount) && getColorTrans(item,transColors) == 'orange',
                        'bg-opacity-50': (item.receiptno || !item.grossamount) && item.lockinfo?.staffcode == staffInfo.code && getColorTrans(item,transColors) == 'orange',
                        'bg-gray border-gray text-gray-500 ': item.islocked,
                    })}>
            <div className="w-100px px-3 p-2 border-right-1 border border-left-0 border border-bottom-0 border border-top-0 border-gray">
                <div className="rounded-5 mb-3 m-auto w-30px h-30px bg-info text-white d-flex flex-center">{item.tableno}</div>
                <div className="text-center"><i className="text-reset bi bi-person-hearts"></i> {item.staffcode}</div>
            </div>
            <div className="p-2 w-100 wrap-table-inf">
                <div className="d-flex flex-stack">
                    <span>#{item.code} {item.tablename} - {item.sectionname}</span>
                    <span> { item.HistoryEntries && item.HistoryEntries.length != 0 && 
                    // timeSince(new Date(`${item.transactiondate} ${item.transactiontim
                        // e}`).getTime())
                    // timeSince(new Date(item.HistoryEntries[0]?.seateddate).getTime())
                        ( item.transactiontime) && <>
                            
                            <i className="text-reset bi bi-clock me-2"></i>
                            <CountUpTimer starttime={new Date(`${item.transactiondate} ${item.transactiontime}`).getTime()} />
                        </>


                    }</span>
                </div>
                <div className="d-flex flex-stack">
                    <span>
                        {item.noofcovers &&
                            <span>{item.noofcovers} {t('order-deep.guest')}</span>
                        }
                        {
                            item.noofcovers && item.lockinfo && <span className="px-1">|</span>
                        }
                        {
                        item.lockinfo && <span>
                            {t('blocked-by')} {item.lockinfo?.staffcode}
                            </span> 
                        }
                    </span>
                    <span className="">{formatCurrent(item.grossamount)} đ</span>
                </div>
                {
                    badge.amount != 0 && <div className="d-flex flex-stack">
                        <span className={clsx(`badge badge-${badge.badge}`)}>{badge.label}</span>
                        {/* {
                            badge.amount && <span className={clsx(`text-${badge.text}`)}>{t('table-service-deep.due')} {formatCurrent(badge.amount)} đ</span>
                        } */}
                        {
                            item.receiptno && <span className={clsx(``)}>{item.receiptno}</span>
                        }
                        
                    </div>

                }
                
            </div>
        </div>
    </div>
    }
    return <>
    <div className="d-flex gap-4">
        {
            viewTableOrder && listDataSlider.length !=0 &&
            <div className="col-sm-5 col-lg-4 col-12">
                {listDataSlider.length > 1 ? 
                    <Slider {...settings} className='slider-wrap-item' dotsClass='slick-dots dot-custom'>
                        {listDataSlider.map((item: any,index: number) => <DivRoot key={index}>{renderItem(item)}</DivRoot>)}
                    </Slider>
                    :
                    listDataSlider.map((item: any,index: number) => <DivRoot key={index}>{renderItem(item)}</DivRoot>)


                }
            </div>
        }
        
    
            <div className=" collapse collapse-horizontal col-sm-5 col-lg-4 col-12" id="collapseWidthExample">
            <form
                className="h-100 w-100"
                onSubmit={handleSubmit(async (data: any) => {
                    // console.log('onsubmit',data)
                    
                })}>
                <div className="wrap-content-card h-100">
                    {isSearch && <div className="position-relative mt-4">
                        
                        <SearchTable data={dataQuery} setTableData={setDataQuery} />
                        <span className="cursor-pointer close-event-s" onClick={e => setSearchOn(false)}>{t('table-service-deep.cancel')}</span>
                    </div>
                    }
                    {filter &&
                        <div className="card-header min-h-auto pt-4 d-flex gap-2">
                            {
                                badgeSearch.map((item: any) => (
                                    checkSearch(item.value) && 
                                    <span className="badge badge-gray d-inline-flex flex-center py-2 gap-1"><span>{item.label}</span> <i onClick={e=>{
                                        let a = JSON.parse(JSON.stringify(filter))
                                        a[item.value] = false
                                        setFilter(a)
                                    }} className="bi bi-x cursor-pointer"></i></span>
                                ))
                            }
                            
                        </div>
                    }
                    {
                        dataQuery && dataQuery.length != 0 ?
                        <div className="card-body wrap-left-order-service pb-0 pt-3">
                            <h5>{t('table-service-deep.my-table')}</h5>
                            {dataQuery?.filter((i: any) => i.staffcode == staffInfo.code)?.map((item: any,index: number) =>{
                                return templateItem(item,index)
                            })}
                            <h5>{t('table-service-deep.other-table')}</h5>
                            {}
                            {dataQuery?.filter((i: any) => i.staffcode != staffInfo.code)?.map((item: any,index: number) =>{
                                return templateItem(item,index)
                            })}
                        </div>
                        :
                        <NoDataTemplate className={'h-100 pt-4'}/>
                    }
                    
                </div>
            </form>
        </div>
        <div className="w-100 px-2 py-4 card shadow">
            <div className="d-flex flex-stack flex-row">
            <div
                className='card d-flex flex-row text-nowrap scrollx-auto tab-custom-hub'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-width='auto'
                data-kt-scroll-offset='0'
              >
                {dataTabs?.map((item: any, index: number) => {
                  return (<span
                    key={index}
                    id={item.id}
                    onClick={() => {
                      setTab(item)
                      refetchTable && refetchTable()
                    }}
                    className={clsx(
                      ' cursor-pointer item-tab',
                      tab?.id != item.id && 'text-gray-600 ',
                      tab?.id == item.id && 'active',
                      index == 0 && ''
                    )}>
                    {item.sectionname}
                  </span>)
                })}
              </div>
                {toolbarAction}
            </div>
                
            <div className="wrap-item-service">
                <div className="d-flex gap-2 flex-wrap py-4">
                {
                    tab && tab.DiningTables && tab.DiningTables.length != 0 && 
                    sortByNumber(tab.DiningTables,'tableno')?.map((item: any,index: number) => (
                    <div key={index} className={clsx(`position-relative border cursor-pointer p-3 rounded-2 w-120px h-120px text-center fs-7 d-flex flex-center`,{
                        'bg-orange text-white border-orange border-solid': item.Trans && item.Trans.length != 0 && (item.Trans[0]?.receiptno || !item.Trans[0]?.grossamount) && getColorTrans(item.Trans[0],transColors) == 'orange',
                        'bg-opacity-50': item.Trans && item.Trans.length != 0 && (item.Trans[0]?.receiptno || !item.Trans[0]?.grossamount) && item.Trans[0]?.lockinfo?.staffcode == staffInfo.code && getColorTrans(item.Trans[0],transColors) == 'orange',
                        // 'disabled': ,
                        'bg-success text-white border-success border-solid': item.Trans && item.Trans.length != 0 && item.Trans[0]?.receiptno && getColorTrans(item.Trans[0],transColors) == 'green',
                        'bg-danger bg-opacity-20 border-danger border-solid': item.Trans && item.Trans.length != 0 && (item.Trans[0]?.receiptno || !item.Trans[0]?.grossamount) && getColorTrans(item.Trans[0],transColors) == 'red',
                        'bg-warning bg-opacity-20 border-warning border-solid': item.Trans && item.Trans.length != 0 && item.Trans[0]?.receiptno && getColorTrans(item.Trans[0],transColors) == 'yellow',
                        'bg-light-info border-info border-solid':item.HistoryEntries && item.HistoryEntries.length != 0 && item.HistoryEntries[0]?.type != STATUSTABLE.free && item.Trans.length == 0,
                        'bg-gray-100 border-primary border-dashed':(!item.HistoryEntries || item.HistoryEntries && (item.HistoryEntries.length == 0 || item.HistoryEntries[0]?.type == STATUSTABLE.free)),
                        'bg-info border-info border-solid text-white': item.Trans && item.Trans.length != 0 && !item.Trans[0]?.receiptno && item.Trans[0]?.grossamount,
                        'bg-gray border-gray text-gray-500': item.islocked,
                    })}
                    onClick={async (e)=>{
                        
                        // console.log('staffInfo.staffcode',staffInfo.staffcode)
                        if(item.Trans && item.Trans.length != 0 && item.Trans[0]?.lockinfo?.staffcode != staffInfo.code && getColorTrans(item.Trans[0],transColors) == 'orange') {
                            if(staffPermissionGroupInfo.isopendintbllockedbystaff) {
                                let aws = await popupQuestion({
                                    title:t('table-service-deep.reset--lock-table-question'),
                                    description:t('table-service-deep.reset--lock-table-question-description'),
                                })
                                if(aws) {
                                    callLockTable({
                                        transactionid: item.Trans[0]?.id,
                                        lockinfo: null,
                                    },setIsCallLock)
                                }
                            }
                            return
                        }
                        
                        if(staffPermissionGroupInfo.isresetdiningtblstatus && item.HistoryEntries && item.HistoryEntries.length != 0 && item.HistoryEntries[0]?.type != STATUSTABLE.free && item.Trans.length == 0) {
                            let aws = await popupQuestion({
                                title:t('table-service-deep.reset-table-question'),
                                description:t('table-service-deep.reset-table-question-description'),
                            })
                            if(aws) {
                                // cancelTableOccupied(item.HistoryEntries[0])
                                const urlApi = `smarthub/hosptransactions/canceltableoccupied`
                                let tempData = {
                                    "diningtablehistoryentryid": item.HistoryEntries[0]?.id,
                                    merchantcode: staffInfo.Merchant?.merchantcode
                                }
                                try {
                                    await ApiService.post(urlApi,tempData)
                                    setDataOccupytable({})
                                } catch (error) {
                                    
                                }
                            }
                            return
                        }
                        
                        else if(item.HistoryEntries && item.HistoryEntries.length != 0 && item.HistoryEntries[0]?.type != STATUSTABLE.free) {
                            // console.log('item.Trans[0]?.grossamount',item.Trans[0]?.grossamount)
                            if(!item.Trans[0]?.grossamount && !item.islocked && item.Trans?.length == 1) {
                                setEdit(true)
                                setViewTableOrder(false)
                                callLockTable({
                                    transactionid: item.Trans[0]?.id,
                                    lockinfo: {
                                        staffcode: staffInfo.code,
                                    },
                                },setIsCallLock)
                            }
                            else {
                                setViewTableOrder(true)
                            }
                            console.log('click edit table',index)
                            setOrderActive(index)
                            
                            let table = tab.DiningTables[index]||{}
                            let id = table.Trans[0]?.id
                            setClickTable(true)
                            // console.log('dataEdit',dataEdit)
                            // console.log('item',item)
                            if(dataEdit?.tableno != item.tableno) {
                                getDetailOrder(id,item)
                            }
                            // setEdit(true)

                        }
                        else {
                            if(item.islocked) {
                                if(staffPermissionGroupInfo.ismanagerprivileges) {
                                    // console.log('item',item)
                                    let aws = await popupQuestion({
                                        title:`${item.tablename} - ${t('table-service-deep.unlock--table-question')} `,
                                        description:`${t('table-service-deep.unlock--table-question-description')} `
                                    })
                                    if(aws) {
                                        funcUnlockOrder({...item,tableid: item.id})
                                    }
                                }
                                else {
                                    popupMessage({icon: 'info', title: t('table-service-deep.lock--table')})
                                }
                            }
                            else {
                                // console.log('add new')
                                let aws = await popupQuestion({
                                    title:`${item.tablename} - ${t('table-service-deep.create--order-erp-question')} `,
                                    description:`${t('table-service-deep.create--order-erp-question-description')} `
                                })
                                if(aws) {
                                    let tableInfo = {
                                        ...tab?.DiningTables[index],
                                        "sectionid": tab.id,
                                        "sectioncode": tab.sectioncode,
                                        "sectionname": tab.sectionname,
                                    }
                                    setInfoTableModal(tableInfo)
                                    // setDataEdit({
                                    //     tablename: item.tablename,
                                    // })
                                    setOrderActive(index)

                                    await createHospTrans(tableInfo,setDataEdit, item)
                                }
                            }
                            
                            
                            
                        }
                    }}>
                        {
                            item.Trans?.length > 1 && 
                            <span className="position-absolute dup-icon"><i className="text-reset fs-4 bi bi-collection"></i></span>
                        }
                        <div>
                            {
                                item.HistoryEntries && item.HistoryEntries[0]?.type == STATUSTABLE.occupied && item.Trans?.length != 0  && <div className={clsx({'opacity-0': !item.Trans[0]?.noofcovers})}>
                                    <i className="bi bi-person-circle text-reset me-1"></i> {item.Trans[0]?.noofcovers} guest
                                </div>
                            }
                            {
                                item.HistoryEntries && item.HistoryEntries[0]?.type == STATUSTABLE.occupied && item.Trans?.length != 0 && item.Trans[0]?.transactiontime &&  <div className="text-center"><CountUpTimer starttime={new Date(`${item.Trans[0]?.transactiondate} ${item.Trans[0]?.transactiontime}`).getTime()} /></div>
                            }
                            
                            <div className="fs-1 fw-bold">{item.tableno}</div>
                            
                            {
                                item.HistoryEntries && item.HistoryEntries[0]?.type == STATUSTABLE.occupied && item.Trans?.length != 0 && <>
                                    <div>{formatCurrent(item.Trans[0]?.grossamount)} đ</div>
                                    <div className=""><i className="bi bi-person-hearts text-reset me-1"></i> {item.Trans[0]?.staffcode}</div>
                                </>
                            }
                        </div>
                    </div>
                    ))
                    
                }
                
                {/* <div className="bg-light-warning p-3 rounded-2 w-100px text-center me-7 mb-4 fs-7 d-flex flex-center">
                    <div>
                        <div>0:17</div>
                        <div className="fs-1 fw-bold">11</div>
                        <div><i className="bi bi-person-hearts me-1"></i> Ronnie L</div>
                    </div>
                    
                </div> */}
            </div>
            </div>
           
        </div>
    </div>
    {edit && 
        <ModalAddEditOrder dataNotify={dataNotify} isCallLock={isCallLock} setIsCallLock={setIsCallLock} refetchTable={refetchTableFunc} refetchCurrent={refetchOrderService} infoTable={infoTableModal} show={edit} dataModal={dataEdit} setDataModal={setDataEdit} setShow={setEdit}/>
    }
    {
        staffPermissionGroupInfo.isaddpayment && showPayTemplate && 
        <ModalPay dataNotify={dataNotify} show={showPayTemplate} setShowPayTemplate={setShowPayTemplate} dataModal={dataEdit} setDataModal={setDataEdit} refetchCurrent={refetchPayOrder} setShow={setShowPayTemplate} setIsCallLock={setIsCallLock}/>
    }
    {
        showTable &&
        <ModalChooseTable 
            showTable={showTable} 
            handleCloseModal={handleCloseModal}
            funcCallback={funcChooseTable}
            isTransfer={true}
            dataEdit={dataEdit}
            disabledId={[dataEdit.tableid]}
            />
    }
    {
        showTransferTable && 
        <ModalTransferTable 
            showTable={showTransferTable} 
            handleCloseModal={handleCloseModalTransfer}
            funcCallback={funcTransTable}
            dataEdit={dataEdit}
            transferTable={transferTable}
            />
    }
    
    <ModalAddGuest isShow={isShowGuest} setIsShow={setIsShowGuest} idHosp={dataEdit?.id} dataModal={dataEdit} setValueData={setNoofcovers}/>
    <ModalChangeCustomer isShow={isShowCustomerInfo} setIsShow={setIsShowCustomerInfo} dataModal={dataEdit} setValueData={setDataEdit} idHosp={dataEdit?.id}/>
    </>
}

export { MBar };

