/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router-dom'
import { MasterLayout } from '../../theme/layout/MasterLayout'
import { App } from '../App'
// auth
import { ApiService, ApiShowError, getCookie } from '../../theme/helpers'
import { AuthModel, getAuth, useAuth } from '../modules/auth'
import { AuthLayout } from '../modules/auth/AuthLayout'
import { ForgotPassword } from '../modules/auth/components/ForgotPassword'
import { Login } from '../modules/auth/components/Login'
// settings
import { ErrorsPage } from '../modules/errors/ErrorsPage'
// page
import { initMode, initPath, SuspensedView, validateMode, validateToken } from '../modules/auth/core/store/componentMenu'
import { FullSreenLayout } from '../modules/auth/FullSreenLayout'
import { IMode } from '../pages/mode/index'
import { OrderManagement } from '../pages/orderManagement'
import { PaymentTerminal } from '../pages/paymentTerminal'
import { TableService } from '../pages/tableService'
import { useTranslation } from 'react-i18next'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { useEffect, useState } from 'react'
import { Error404 } from '../modules/errors/components/Error404'


export async function isAuth() {
  try {
    const auth = await getAuth() as any
    // console.log('auth',auth)
    // console.log('getCookie',getCookie('token'))
    if(!auth?.user?.id && getCookie('token')) {
      let username = auth.user?.username
          let tempData = {
            funcName: 'get local auth not found auth?.user?.id',
            time: new Date(),
            username: username
          }
          ApiService.post(`smarthub/logs/write`, tempData)
      return {}
    }
    let newAuth = {} as any
    if(auth?.user?.id) {
      let body = {system: `merchant_console`}
      newAuth = await ApiService.post(`/auth/authenticate/${auth?.user?.id}`,body)
    }
    
    
    return newAuth?.data||{}
  } catch (error) {
    ApiShowError(error)
  }
  return undefined
}

const AppRoutes = () => {
  const { t } = useTranslation()
  // async function loadInitAuth() {
  //   const auth: AuthModel | undefined = getAuth()
  //   if (auth && auth.user?.id) {
  //     const { isAuthenticated, newAccessToken } = await isAuth()||{}
  //     if (newAccessToken && isAuthenticated) {
  //       // setCookie('token', newToken, 365)
  //       // await saveAuth({ ...auth, token: newAccessToken })
  //     }
  //     if (isAuthenticated) { return redirect("/") }
  //   }
  //   return null
  // }
  const menuStore = null //useStore((state:any) => state.menu)
  let menuRouter = [] as any

  if (menuStore) {
  } else {
    let defaultMenu = [
      {
        path: "",
        element: <Navigate to="/dashboard" />,
        isHiddenMenu: true,
        
      },
      {
        path: "dashboard",
        element: <SuspensedView children={<DashboardWrapper />} />,
        loader: validateToken,
      },
      {
        title: t('mode'),
        ...initPath("mode"),
        icon: '/media/icons/custom/request.svg',
        // loader: validateToken,
        element: <SuspensedView children={<IMode />} />,
        permission: '',
      },

    ] as any
    menuRouter = [...defaultMenu, ...menuRouter]
  }
  
  let menuFullScreen = [
    {
      title: t('orders-hub'),
      ...initMode("orders-hub"),
      icon: '/media/icons/custom/tnf_order_request.svg',
      element: <SuspensedView children={<OrderManagement />} />,
      permission: ''
      
    },
    {
      title: t('table-service'),
      ...initMode("table-service"),
      loader: () => validateMode('table-service'),

      icon: '/media/icons/custom/tnf_advance.svg',
      element: <SuspensedView children={<TableService />} />,
      permission: ''
    },
    {
      title: t('payment-terminal'),
      ...initMode("payment-terminal"),
      loader: () => validateMode('payment-terminal'),
      icon: '/media/icons/custom/tnf_payment.svg',
      element: <SuspensedView children={<PaymentTerminal />} />,
      permission: ''
    },
    {
      path: "*",
      element: <Error404 />,
      errorElement: <ErrorsPage />,
    },
  ]
  const modeMenuFull = () => {
    // const { user } = await getAuth() as any

    // console.log('user',user)
    // if (user && user.visibleto) {
    //   let arrVisible = user.visibleto.split(',')
    //   // console.log('arrVisible',arrVisible)
    //   let a = menuFullScreen
    //   a = a.filter((i: any) => arrVisible.includes(i.path))
    //   console.log('menuFullScreen',menuFullScreen)
    //   console.log('a',a)
    //   menuFullScreen = a
    //   return a
    // }
    console.log('menuFullScreen',menuFullScreen)
    return menuFullScreen
  }
  
  return <RouterProvider router={createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorsPage />,
      children: [
        {
          path: "/",
          element: <MasterLayout />,
          children: menuRouter,
          // loader: validateToken,

        },
        {
          path: "/mode/*",
          element: <FullSreenLayout />,
          errorElement: <ErrorsPage />,
          loader: () => validateToken(),
          children: menuFullScreen
        },
        {
          path: "/*",
          element: <Error404 />,
          errorElement: <ErrorsPage />,
        },
        {
          path: "/",
          element: <AuthLayout />,
          errorElement: <ErrorsPage />,
          // loader: loadInitAuth,
          children: [
            {
              path: 'login',
              element: <Login />,
            },
            {
              path: "forgot-password",
              element: <ForgotPassword />,
            },
            
          ]
        },
        
      ],
    },
  ])} />
}

export { AppRoutes }

