/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { ContentHeader } from '../../../theme/layout/components/content'
import { useAuth } from '../../modules/auth'
import { LocalData } from '../../modules/api/localData'
import { useAdvanceForme, useOrderForme, usePaymentForme } from '../../modules/api/requests'
import clsx from 'clsx'
import { DivRoot, InputSelect, KTFormItem, SearchTable } from '../../../theme/partials'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { ModalAddEditOrder } from '../orderManagement/tabs/addOrder'
import { useStoreMode } from '../../modules/auth/core/store'
import { useTranslation } from 'react-i18next'
import { validateToken } from '../../modules/auth/core/store/componentMenu'


export function IMode() {
  const { t } = useTranslation()
  const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm()
  const [edit, setEdit] = useState(false)
  const [dataModal, setDataModal] = useState<any>({})
  const storeMode = useStoreMode((state:any) => state)

  const { auth } = useAuth()
  const handleQuickOrder = () => {
    // console.log('ádas')
    setEdit(true)
  }
   useEffect(()=>{
    const a = async() => {
      let check = await validateToken()
      // console.log('sqeqeqw',check)
      if(check !== null) {
        navigate('/login')
      }
    }
    a()
   },[])
  const { user } = auth || {}
  const menu = [
    { to: "#", label: "Quick Order", badge: 'light-success', color: 'success', action: handleQuickOrder, key: 'quick-order', },
    { to: "/mode/orders-hub", label: t('orders-hub'), badge: 'light-primary', color: 'primary', key: 'orders-hub', },
    { to: "/mode/table-service", label: t('table-service'), badge: 'light-info', color: 'info', key: 'table-service', },
    { to: "/mode/payment-terminal", label: t('payment-terminal'), badge: 'light-warning', color: 'warning', key: 'payment-terminal', },
  ]
  const [menuLocal, setMenuLocal] = useState<any>([])
  const { currentUser } = useAuth() as any
  let staffPermissionGroupInfo = useStoreMode((e: any) => e.info)?.staffPermissionGroupInfo || {}
  // console.log('staffPermissionGroupInfo',staffPermissionGroupInfo)
  const navigate = useNavigate();
  
  useEffect(() => {
    if (currentUser && currentUser.visibleto) {
      let arrVisible = currentUser.visibleto.split(',')
      // console.log('menuLocal',menuLocal)
      let a = menu//JSON.parse(JSON.stringify(menuLocal))
      a = a.filter((i: any) => arrVisible.includes(i.key))
      if(!staffPermissionGroupInfo.isaddpayment) {
        a = a.filter((i: any) => i.key != 'payment-terminal')
      }
      // console.log('a',a)
      setMenuLocal(a)
    }
  }, [currentUser,staffPermissionGroupInfo])
  return (
    !storeMode.info?<></>:
    <>
      <SearchTable data={[]} className='search-fix-header' keySearch={['creater', 'code', 'title', 'fullname']} />
      <div className={`card h-100 mt-10`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark'>{t(`hi-fullName`, { fullName: user?.fullname })}</span>
          </h3>
          <div className='card-toolbar'></div>
        </div>
        <div className='card-body pt-5'>
          <div className="d-flex flex-wrap flex-center">
            {menuLocal.map((item: any, index: number) => {
              const count = index + 12
              return <Link to={item.to} onClick={e => {
                // console.log('action',item)
                if (item.action) {
                  item.action()
                }
              }} key={index} className={clsx(`border border-${item.color} shadow rounded min-w-200px p-7 me-6 mb-3 bg-${item.badge}`)}>
                <div className="d-flex align-items-center">
                  <div className={clsx(`fs-3 fw-bolder counted text-${item.color}`)} data-kt-countup="true" data-kt-countup-value={count} data-kt-countup-prefix="">{count}</div>
                </div>
                <div className={clsx(`fs-1 text-${item.color}`)}>{item.label}</div>
              </Link>
            })}
          </div>
        </div>
      </div>
      <ModalAddEditOrder show={edit} dataModal={dataModal} infoTable={{}} setDataModal={setDataModal} setShow={setEdit} />
    </>
  )
}
