import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError } from "../../../../theme/helpers";
import { KTButton, SearchTable } from "../../../../theme/partials";
import { getAuth, useAuth } from "../../../modules/auth";
import { useStoreMode, useStorePrint } from "../../../modules/auth/core/store";
import { popupLoading, popupMessage } from "../../../modules/messages";
import { checkPaid } from "./activeOrder";
import { ModalAddEditOrder } from "./addOrder";
import { cancelOrder, converDataWithCustom, funcSend, handleClickGetItemKOT, notifyPayment } from "./functionOrder";
import { DetailItem, HeadItemTemplate, KITCHENSTATUS, MemberInforAndNote, NoDataTemplate, PromotionAndSum, SALETYPE, TRANSACTIONSTATUS } from "./templateOrder";
import { Trans, useTranslation } from "react-i18next";
import clsx from "clsx";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});
export const badgeSearch = [{
    label:  <Trans i18nKey="orders-hub-deep.not-paid"/>,
    value: "notPaid",
},
{
    label:  <Trans i18nKey="orders-hub-deep.paid"/>,
    value: "isPaid",
},
{
    label: <Trans i18nKey="orders-hub-deep.dinner-in"/>,
    value: "dinein",
},
{
    label: `Takeaway`,
    value: "takeaway",
},
{
    label: <Trans i18nKey='orders-hub-deep.curbside'/>, //"Cubsude",
    value: "is_curbside",
},
{
    label: <Trans i18nKey='orders-hub-deep.delivery'/>,
    value: "is_delivery",
},
{
    label: <Trans i18nKey='orders-hub-deep.no-behavior'/>,
    value: "no_behavior",
},
{
    label: <Trans i18nKey='orders-hub-deep.takeout'/>,
    value: "takeout_in",
},
]
const urlApi = 'settings/customers'
const MLive = (props:any) => {
    const { t } = useTranslation();
    const {dataApi,filter,setFilter, isSearch, setSearchOn, dataNotify,setIsClickTab,isClickTab} = props
    // const { permission } = getLocationData()
    const [note,setNote] = useState('')
    const [isloading,setIsloading] = useState(false)

    const callBackReFetch = async(index?: any) => {
        let indexActive = orderActive
        if(index) {
            indexActive = index
            setOrderActive(index)
        }
        // console.log('indexActive',indexActive)
        // console.log('dataQuery',dataQuery)
        // console.log('dataModal',dataModal)
        if(!dataQuery[indexActive] || indexActive == -1) {
            setOrderActive(-1)
            setDataModal(null)
            return
        }
        await handleClickGetItemKOT(dataQuery[indexActive],setDataModal,setNote,setIsloading, orderActive,dataQuery, setDataQuery)
        // console.log('data')
        
    }
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}

    const navigate = useNavigate();
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(0)
    const [edit ,setEdit] = useState<any>(false)
    const [dataModal ,setDataModal] = useState<any>({})
    
    useEffect(() => {
        if(dataApi) {
            // console.log('call getData',dataApi)
            getData(dataApi||[])
        }
    }, [dataApi,dataNotify])
    
    
    useEffect(() => {
        if(props.filter) {
            // console.log('filter',props.filter)
        }
    }, [props.filter])
    const getData = (data: any) => {
        if(isClickTab) {
            // setDataModal(null)
            // setOrderActive(-1)
            // setIsClickTab(false)
        }
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
        })
       
        if(data.length == 0) {
            setDataModal(null)
            setOrderActive(-1)
        }
        else if(!dataModal) {
            handleClickGetItemKOT(data[0],setDataModal,setNote, setIsloading)
            setOrderActive(0)
        }
        else if(dataModal && dataNotify && dataModal?.id == dataNotify?.id 
            && (dataNotify?.transactionstatus != TRANSACTIONSTATUS.completed 
                && dataNotify?.transactionstatus != TRANSACTIONSTATUS.cancel 
                && dataModal?.transactionstatus != TRANSACTIONSTATUS.completed 
                && dataModal?.transactionstatus != TRANSACTIONSTATUS.cancel)) {
            if(!edit) {
                handleClickGetItemKOT(dataModal,setDataModal,setNote, setIsloading)
            }
        }
        else {
            handleClickGetItemKOT(data[0],setDataModal,setNote, setIsloading)
            setOrderActive(0)
        }
        setDataQuery(data)
    
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })

    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>

    let storePrint = useStorePrint((e: any)=> e)||{}
    const [user,setUser] = useState<any>({})
    useEffect(()=> {
        const getInitalAuth = async() => {
            const dataAuth = await getAuth()||{} as any
            setUser(dataAuth.user||{})
        }
        getInitalAuth()
    },[])
    
    const funcCallback = async(item: any, index: number, statusDefault: string) => {
        // let tempData = dataQuery.map((i: any) => i)
        // tempData[index] = converDataWithCustom(item,status)
        let status = statusDefault
        await funcSend(item,setDataModal,user, staffInfo, storePrint,)
        if(item && checkPaid(item).isPaid) {
            status = KITCHENSTATUS.completed
            let temp = {
                transactionid: item?.id,
                staffcode: staffInfo.code,
                staffreceiptname: staffInfo.nameonreceipt,
            }
            await ApiService.post(`lscentral/hosporders/createhosporder_paid`, temp)
        }
        else {
            try {
                const urlApi = `smarthub/hosptransactions/update/${item.id}`
                let tempApproved = {
                    transactionstatus: status,
                    staffcode: staffInfo.code,
                    staffreceiptname: staffInfo.nameonreceipt,
                    HospTransactionLines: item?.HospTransactionLines?.filter((i: any) => i.kitchenstatus == KITCHENSTATUS.notSent).map((i: any) => {
                        return {
                            ...i,
                            kitchenstatus: KITCHENSTATUS.sent,
                        }
                    })
                }
                await ApiService.put(urlApi, tempApproved)
                
            } catch (error) { ApiShowError(error) }
        }
        callBackReFetch(0)
        popupMessage({ icon: 'success', autoClose: true })
        
    }

    const checkSearch = (value: any) => {
        return filter[value]
    }
    
    // const [dataActive,setDataActive] = useState<any>(null)
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}

    return <>
    <div className="row">
        <div className="col-7 ps-0">
        <form
            className="h-100 w-100"
            onSubmit={handleSubmit(async (data: any) => {
                console.log('onsubmit',data)
               
            })}>
            <div className="wrap-content-card h-100">
                {isSearch && <div className="position-relative mt-4">
                    
                    <SearchTable data={dataQuery} setTableData={setDataQuery} />
                    <span className="cursor-pointer close-event-s" onClick={e => setSearchOn(false)}>{t('orders-hub-deep.cancel')}</span>
                </div>
                }
                {filter &&
                    <div className="card-header min-h-auto pt-4 d-flex gap-2">
                        {
                            badgeSearch.map((item: any,index: number) => (
                                checkSearch(item.value) && 
                                <span key={index} className="badge badge-gray d-inline-flex flex-center py-2 gap-1"><span>{item.label}</span> <i onClick={e=>{
                                    let a = JSON.parse(JSON.stringify(filter))
                                    a[item.value] = false
                                    setFilter(a)
                                }} className="bi bi-x cursor-pointer"></i></span>
                            ))
                        }
                        
                    </div>
                }
                {
                    dataQuery && dataQuery.length != 0 ?
                    <div className="card-body pb-0 pt-4">
                        {dataQuery?.map((item: any,index: number) =>{
                            return <div className="wrap-item" key={index} onClick={e=>{
                                if(orderActive == index) {
                                    return
                                }
                                setOrderActive(index)
                                handleClickGetItemKOT(item,setDataModal,setNote, setIsloading)
                            }}>
                                {
                                    <HeadItemTemplate isTagPaid={true} checkPaid={checkPaid(item)} item={item} index={index} orderActive={orderActive}/>
                                }
                            
                        </div>
                        })}
                    </div>
                    :
                    <NoDataTemplate className={'h-88vh pt-4'}/>
                }
                
            </div>
            
            
            
        </form>
        </div>
        <div className="col-5 right-detail px-0">
            {dataModal && orderActive > -1 ? 
                <div className="p-3 card shadow">
                    <h6 className="d-flex flex-stack">
                        <span>{dataModal.tablename} - {dataModal.sectionname}</span>
                        <div className="d-flex min-h-30px gap-1">
                            {dataModal.receiptno && <div className="d-flex flex-stack"> 
                                <span>{dataModal.receiptno} </span>
                            </div>}
                            {
                            (staffPermissionGroupInfo.isvoidtransaction) && 
                                <span onClick={e=>cancelOrder(dataModal,orderActive,dataQuery,setDataQuery, staffInfo)} className="btn btn-sm btn-icon btn-light btn-active-light-danger"><i className="bi bi-x-lg"></i></span>
                            }
                        </div>
                    </h6>
                    <div className={clsx({"loading-calculate":isloading})}>
                    {
                        isloading &&
                        <div className="bg-blur"><div className="loader-cal"></div></div>
                    }
                        <DetailItem className="wrap-hub-details" note={note||dataModal.message} {...dataModal} dataModal={dataModal} setDataModal={setDataModal} />
                        <div className="wrap-footerItem">
                            <MemberInforAndNote note={note||dataModal.message} dataModal={dataModal} {...dataModal} setDataModal={setDataModal} />
                            <PromotionAndSum paidPayment={dataModal.TransactionPaymentEntries} {...dataModal} />
                            
                            <div className="d-flex gap-4 mt-3 px-3">
                                <button onClick={e => {
                                    funcCallback(dataModal,orderActive,dataModal.nextStatus)
                                }} className={clsx("btn btn-sm btn-info w-50",{"disabled": dataModal.saletype != SALETYPE.dinein && !checkPaid(dataModal).paided})}><i className="bi bi-printer me-1"></i>{t('orders-hub-deep.approve')}</button>
                                {/* <button className="btn btn-sm btn-success w-50"><i className="bi bi-printer me-1"></i>{t('order-deep.print')}</button> */}
                                {
                                    staffPermissionGroupInfo.isopensalepos &&
                                    <button onClick={e=>{
                                    // setDataModal(dataModal)dataModal.TransactionPaymentEntries
                                    setEdit(true)
                                    }} className={clsx("btn btn-sm btn-primary w-50",{'disabled': checkPaid(dataModal).isPaid })}><i className="bi bi-pencil me-1"></i>{t('orders-hub-deep.edit')}</button>
                                }
                                
                            </div>
                            {/* <div className="p-3">
                                
                            </div> */}
                        </div>
                    </div>
                    
                    
                </div>
                :
                <NoDataTemplate className={'h-100 pt-4'}/>
            }
            
        </div>
    </div>
    {
        edit && dataModal?.id &&
        <ModalAddEditOrder dataNotify={dataNotify} funcCallback={funcCallback} show={edit} dataModal={dataModal} setDataModal={setDataModal} setShow={setEdit} refetchCurrent={callBackReFetch} />

    }
        
    </>
}

export { MLive };

