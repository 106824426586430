import { useEffect, useRef, useState } from "react";
import { ApiService, ApiShowError, formatCurrent, sortByNumber, unique_arr } from "../../../../theme/helpers";
import { popupAlertBottom, popupLoading, popupMessage, popupQuestion } from "../../../modules/messages";
import { Trans } from "react-i18next";
import { caculPromo, KITCHENSTATUS, renderItemProduction } from "./templateOrder";
import { connectPrinterWithTimeout, findAndPrint, prePrint, printReceipt } from "../../mode/print";
import { t } from "i18next";
import localforage from "localforage";
import { getAuth } from "../../../modules/auth";
import Swal from "sweetalert2";
import { isArray } from "@tsparticles/engine";



export function add15MinutesToTime(inputTime: string) {
    // Chuyển đổi thời gian đầu vào thành đối tượng Date
    var inputDate = new Date("2022-01-01 " + inputTime);

    // Cộng thêm 15 phút
    inputDate.setMinutes(inputDate.getMinutes() + 15);

    // Định dạng thời gian mới
    var hours = inputDate.getHours() as any;
    var minutes = inputDate.getMinutes() as any;
    var seconds = inputDate.getSeconds() as any;

    // Đảm bảo định dạng có hai chữ số
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    // Tạo chuỗi thời gian mới
    var newTime = hours + ':' + minutes + ':' + seconds;

    // Trả về thời gian mới
    return newTime;
}
export function timeSince(date:any) {
    let today = new Date().getTime()
    // console.log('today - date',today - date)
    var seconds = Math.floor((today - date) / (1000));
    var interval = seconds / (24*3600);
    if (interval > 1) {
      return <Trans i18nKey={'number-days'} values={{number: Math.floor(interval)}} />
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return <Trans i18nKey={'number-hours'} values={{number: Math.floor(interval)}} />
    }
    interval = seconds / 60;
    if (interval > 1) {
     return <Trans i18nKey={'number-minutes'} values={{number: Math.floor(interval)}} />
    }
    interval = interval < 0? 0 : interval
   return <Trans i18nKey={'number-seconds'} values={{number: Math.floor(interval)}} />
  }
export const converDataWithCustom = (item: any, status: string) => {
    if(status == 'Open'||status == 'Schedule') {
        item.transactionstatus = status
        item.headerbadge = 'orange'
        item.nextStatus = 'Active'
    }
    if(status == 'Active') {
        item.transactionstatus = status
        item.headerbadge = 'primary'
        item.nextStatus = 'Completed'
    }
    if(status == 'Ready') {
        item.transactionstatus = status
        item.headerbadge = 'primary'
        item.nextStatus = 'Completed'
    }
    if(status == 'Completed') {
        item.transactionstatus = status
        item.headerbadge = 'primary'
        item.nextStatus = 'Open'
    }
    // if(status == 'Ready-to-serve') {
    //     item.transactionstatus = status
    //     item.actionTextRight = 'Sent'
    //     item.badgeRight = 'info'
    //     item.headerbadge = 'primary'
    //     item.nextStatus = 'Served'
    // }
    if(status == 'Served' || status == 'Cancel') {
        item.transactionstatus = status
        item.actionTextLeft = 'Ready'
        item.badgeLeft = 'success'
        item.actionTextRight = 'Served'
        item.badgeRight = 'warning'
        item.headerbadge = 'gray'
    }
    return item
}
export const funcChangeStatusTrans = async(item: any, status: string) => {
    try {
        const urlApi = `smarthub/hosptransactions/update/${item.id}`
        await ApiService.put(urlApi, {transactionstatus: status})
        popupMessage({ icon: 'success', autoClose: true })
    } catch (error) { ApiShowError(error) }
}
export const cancelOrder = async(item: any,index: number,dataQuery: any, setDataQuery: any,staffInfo: any) => {
    let asw = await popupQuestion({title:'Do you want to cancel this order?'})
    if(asw) {
        popupLoading()
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            let temp = {
              transactionstatus: 'Cancel',
              staffcode: staffInfo.code, 
              staffreceiptname: staffInfo.fullname,
            }
            await ApiService.put(urlApi, temp)
            let tempData = dataQuery.map((i: any) => i)
            tempData = tempData.filter((i: any,ind: number) => ind != index)
            setDataQuery(tempData)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error,'cancelOrder',staffInfo) }
    }
}
export function removeVietnameseAndSpecialChars(inputString: string, space?: string) {
    if (!inputString) return '';
    var AccentsMap = ['aàảãáạăằẳẵắặâầẩẫấậ', 'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ', 'dđ', 'DĐ', 'eèẻẽéẹêềểễếệ', 'EÈẺẼÉẸÊỀỂỄẾỆ', 'iìỉĩíị', 'IÌỈĨÍỊ', 'oòỏõóọôồổỗốộơờởỡớợ', 'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ', 'uùủũúụưừửữứự', 'UÙỦŨÚỤƯỪỬỮỨỰ', 'yỳỷỹýỵ', 'YỲỶỸÝỴ',]
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substring(1) + ']', 'g')
      var char = AccentsMap[i][0]
      inputString = String(inputString).replace(re, char).toLowerCase()
    }
    return inputString
      .replace(/[^\w\s]/gi, '') // loại bỏ các ký tự đặc biệt
      .replace(/\s+/g, ' ') // gộp các khoảng trắng liên tiếp
      .trim()
      .normalize("NFD") // Chuyển về Unicode Normalization Form D
      .replace(/[\u0300-\u036f]/g, "")  // Loại bỏ dấu tiếng Việt
      .replace(/[^a-zA-Z0-9.]/g, space || "") // loại bỏ chữ cái tiếng việt + space;
      .replace(/^-+|-+$/g, '') // + loại bỏ nếu có space ở 2 đầu
  }
export function isArrayNotEmpty(arr?: any) {
    if (arr === undefined) return false;
    return Array.isArray(arr) && arr.length > 0;
}
export function debounce(fn: Function, ms = 300) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};
export function searchWithKeyword(arr: any[] | undefined, keyword: string, fields: string[]) {
    if (!arr || !isArrayNotEmpty(arr)) return [];
    if (!keyword) return arr;
    const keywordNotAccents = removeVietnameseAndSpecialChars(keyword)

    return arr.filter((item) => {
        for (let i = 0; i < fields.length; i++) {

            const field = fields[i];
            const isDot = field.includes('.');
            let text = String(item[fields[i]]);

            if (isDot) {
                const fieldsDot = field.split('.');
                let obj = item;
                for (let j = 0; j < fieldsDot.length; j++) {
                    obj = obj[fieldsDot[j]];
                }
                text = String(obj);
            }
            if (!text) return false;
            if (text == "null") return false;
            const fieldNotAccents = removeVietnameseAndSpecialChars(text)
            if (fieldNotAccents.includes(keywordNotAccents)) return true;
        }
        return false;
    });
}

export const CountUpTimer = (props: any) => {
    let {starttime} = props
    const [millisecondsElapsed, setMillisecondsElapsed] = useState(0);
    const [intervalId, setIntervalId] = useState<any>(null);
    useEffect(()=>{
        let time = new Date().getTime() - starttime
        startTimer()
        setMillisecondsElapsed(time)
    },[starttime])
    
    const startTimer = () => {
      if (!intervalId) {
        setIntervalId(
          setInterval(() => {
            setMillisecondsElapsed((prevMilliseconds: any) => prevMilliseconds + 10);
          }, 10)
        );
      }
    };
  
    const pauseTimer = () => {
      clearInterval(intervalId);
      setIntervalId(null);
      setMillisecondsElapsed(0)
    };
  
    
    // console.log('formatValue(millisecondsElapsed)',formatValue(millisecondsElapsed))
    return <>{formatValue(millisecondsElapsed)}</>
  };
export const formatValue = (value: any) => {
  const hours = Math.floor(value / 3600000);
  const minutes = Math.floor((value % 3600000) / 60000);
  const seconds = Math.floor((value % 60000) / 1000);
  if (hours >= 24) {
    let day = Math.floor(value / (24*3600000));
    return <Trans i18nKey={"number-days"} values={{number: day}} />
  }
  return `${hours>=1 ? `${hours.toString().padStart(2, "0")}:`:''}${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
export const handleClickGetItemKOT = async(item: any,setDataActive: any, setNote: any,setIsloading: any, orderActive?: any, dataQuery?: any, setDataQuery?: any) => {
  setIsloading(true)
//   console.log('item',item)
    try {
        const urlApi = `smarthub/hosptransactions/findactive/${item.id}`
        let {data} = await ApiService.get(urlApi)
        data = converDataWithCustom(data,data.transactionstatus)
        if(data.kots && data.kots.length!=0) {
            setNote(data.kots[0]?.comments)
        }
        else {
            setNote(data.message)
        }
        setDataActive(data)
        if(orderActive && setDataQuery && dataQuery) {
            let a = dataQuery
            a[orderActive] = data
            setDataQuery(a)
        }
        setIsloading(false)

        return data
    } catch (error) {
        
    }
  
}
export const updateOnERP = async(dataModal: any,staffInfo: any) => {
  try {
    const urlApi = `lscentral/hosporders/updatehosporder`//`lscentral/hosporders/upserttransaction2`
    // let HospTranLines = JSON.parse(JSON.stringify((tempLines||dataModal.HospTransactionLines).filter((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)))
    // console.log('HospTranLines',HospTranLines)
    // if(HospTranLines.length == 0) {
      
    // }
    // let tempHospTranLines = [] as any
    // HospTranLines.forEach((i: any) => {
    //     let a = [{
    //         id: i.id,
    //         lineno: i.lineno,
    //         itemno: i.itemno,
    //         quantity: i.quantity,
    //         entrytype: i.entrytype,
    //         barcodeno: i.barcodeno,
    //         kitchenstatus: i.kitchenstatus,
    //         customizations: i.customizations,
    //     }]
    //     let b = i.customizations?.map((i: any) => ({
    //         id: i.id,
    //         lineno: i.lineno,
    //         itemno: i.itemno,
    //         quantity: i.quantity,
    //         entrytype: i.entrytype,
    //         barcodeno: i.barcodeno,
    //         kitchenstatus: i.kitchenstatus,
    //     }))
    //     let c = a.concat(b)
    //     tempHospTranLines = tempHospTranLines.concat(c)
    // })
    
    let tempData = {
        transactionid: dataModal.id,
        "staffcode": staffInfo.code,
        "staffreceiptname": staffInfo.fullname,
    }
    // console.log('dataModal',dataModal)
    // return
    ApiService.post(urlApi, tempData)
    
    
} catch (error) { ApiShowError(error,'updateOnERP', staffInfo) }
}
export const createOnERP = async(dataModal: any,setDataModal?: any,tempLines?: any) => {
  try {
      const urlApi = `lscentral/hosporders/updatehosporder`//`lscentral/hosporders/upserttransaction2`
      let HospTranLines = JSON.parse(JSON.stringify((tempLines||dataModal.HospTransactionLines).filter((i: any) => i.kotline?!i.kotline.isvoided:true)))
      let tempHospTranLines = [] as any
      HospTranLines.forEach((i: any) => {
          let a = [{
              id: i.id,
              lineno: i.lineno,
              itemno: i.itemno,
              quantity: i.quantity,
              entrytype: i.entrytype,
              barcodeno: i.barcodeno,
              kitchenstatus: i.kitchenstatus,
              customizations: i.customizations,
          }]
          let b = i.customizations?.map((i: any) => ({
              id: i.id,
              lineno: i.lineno,
              itemno: i.itemno,
              quantity: i.quantity,
              entrytype: i.entrytype,
              barcodeno: i.barcodeno,
              kitchenstatus: i.kitchenstatus,
          }))
          let c = a.concat(b)
          tempHospTranLines = tempHospTranLines.concat(c)
      })
      
      let tempData = {
          id: dataModal.id,
          HospTransactionLines: tempHospTranLines,
          tableid: dataModal.tableid,
          tableno: dataModal.tableno,
          tablename: dataModal.tablename,
          receiptno: dataModal.receiptno,
          storeno: dataModal.storeno,
          hospitalitytype: dataModal.hospitalitytype,
          terminalno: dataModal.terminalno,
          customerinfo: dataModal.customerinfo,
          code: dataModal.code,
          transactiondate: dataModal.transactiondate,
          transactiontime: dataModal.transactiontime,

      }
      // console.log('dataModal',dataModal)
      // return
      let {data} = await ApiService.post(urlApi, tempData)
      let {updatedTransaction, updatedTransactionlines} = data
      if(updatedTransaction && updatedTransactionlines)  {
          let idsTrans = updatedTransactionlines?.map((i: any) => i.id)
          let tempItems = dataModal.HospTransactionLines
          let tempHospTransactionLines = tempItems.filter((i: any) => !idsTrans.includes(i.id))
          updatedTransactionlines = updatedTransactionlines?.map((i: any) => {
              let record = tempItems.find((t: any) => t.id == i.id)||{}
              return {...i, HospItem: record.HospItem, kitchenstatus: record.kitchenstatus}
          })
          let transLine = tempHospTransactionLines.concat(updatedTransactionlines)
          let tempDataModal = {
              ...updatedTransaction,
              HospTransactionLines:  sortByNumber(transLine,'lineno')
          }
          // console.log('tempDataModal',tempDataModal)
          if(tempLines) {
            setDataModal && setDataModal(tempDataModal)
          }
      }
      
  } catch (error) { ApiShowError(error,'createOnERP') }
}
export const cancelOrderInModal = async(item: any,refetch: any,callPrint: any, staffInfo: any) => {
  let asw = await popupQuestion({title:'Do you want to clear this order?'})
  if(asw) {
      callPrint && callPrint()
      try {
          popupLoading()
          const urlApi = `smarthub/hosptransactions/update/${item.id}`
          let tempData = {
            transactionstatus: 'Cancel',
            staffcode: staffInfo.code, 
            staffreceiptname: staffInfo.fullname,
          } 
          await ApiService.put(urlApi, tempData)
          // refetchTable && refetchTable()
          refetch && refetch()
          // refetchOrderService()
          popupMessage({ icon: 'success', autoClose: true })
      } catch (error) { ApiShowError(error,'cancelOrderInModal',staffInfo) }
  }
}
export const getColorTrans = (item: any, transColors: any) => {
  let a = transColors?.find((i:any)=>i.code == item?.code)
  if(a) {
      return a.color
  }
  return null
}
export const callLockTable = async(tempData: any, setIsCallLock: any) =>{
  // 
  let urlApi = `smarthub/hosptransactions/lockorreleasetableupdating`
  if(!tempData.transactionid) return
  try {
      await ApiService.post(urlApi, tempData)
      if(tempData.lockinfo) {
        setIsCallLock && setIsCallLock(true)
      }
      else {
        setIsCallLock && setIsCallLock(false)
      }
  } catch (error) { 
    ApiShowError(error,'callLockTable',) 
  }

}
export const getDataTrans = async(dataModal: any,setDataModal: any, user: any, staffInfo: any) => {
  // console.log('dataModal',dataModal)
  if(!dataModal.id) return []
  const method = 'get'
  const urlApi = `smarthub/hosptransactionlines/findbytransactionid/${dataModal.id}`
  let temp = {} as any
  try {
      let {data} = await ApiService[method](urlApi)
      let dataTemp = JSON.parse(JSON.stringify(dataModal))
      temp = {
          ...dataTemp,
          user: user,
          HospTransactionLines: dataTemp.HospTransactionLines?.map((i: any) => {
              let record = data?.find((d: any) => d.lineno == i.lineno)
              let temp = {
                  ...i,
              }
              if(record) {
                  temp.kitchenstatus = record.kitchenstatus
                  temp.id = record.id

              }
              return temp
          })||[]
      }
  // console.log('temp',temp)
      setDataModal(temp)
  } catch (error) { 
    ApiShowError(error,'getDataTrans' ,staffInfo)
  }
  return temp.HospTransactionLines ? temp.HospTransactionLines : false
}
export const checkConnectPrinterToSend = async (dataModal: any, itemLines: any, staffInfo: any) => {
  let isCheck = [] as any
  let dataRender = renderItemProduction(unique_arr(itemLines,'lineno'))
  let merchantsData = await localforage.getItem('merchantsData')||{} as any

  let {productionsections, sectiondispstatmappings} = merchantsData
  let sectionid = dataModal?.sectionid

  let username = (await getAuth() as any).user?.username

  // (async function () {
      for await (const i of dataRender) {
          let code = productionsections?.find((p: any) => p.type == i.name)?.code
          // console.log('code',code)
          if(code) {
           let printInf = sectiondispstatmappings?.find((s: any) => s.hospdiningareasectionid == sectionid && s.productionsectioncode == code)?.PrinterList
          //  console.log('printInf',printInf)
           
           if(printInf) {
               let tempData = {
                   host: printInf.host,
                   port: printInf.port
               }
              await connectPrinterWithTimeout(tempData, 10000)
              .then((printer) => {
                  console.log('Kết nối thành công đến máy in');
                  isCheck.push(true)
                  // Thực hiện thao tác in
              })
              .catch((error) => {
                  isCheck.push(false)
                  let tempData = {
                      funcName: 'checkConnectPrinterToSend',
                      error: error.message,
                      staffcode: staffInfo?.code,
                      username: username,
                  }
                  ApiService.post(`smarthub/logs/write`, tempData)
                  console.log('Lỗi:', error?.message);
              });
               
               
           }
           else {
               popupMessage({description:"Please check config printer!", icon: 'info'})
               let tempData = {
                  funcName: 'checkConnectPrinterToSend',
                  error: `Please check config printer!`,
                  staffcode: staffInfo?.code,
                  username: username,
              }
              ApiService.post(`smarthub/logs/write`, tempData)
           }
          }
          else {
           popupMessage({description:"Please check setup productionsections item!", icon: 'info'})
           
           let tempData = {
              funcName: 'checkConnectPrinterToSend',
              error: `Please check setup productionsections item!`,
              staffcode: staffInfo?.code,
              username: username,
              
          }
          ApiService.post(`smarthub/logs/write`, tempData)
       }
    
        break; // Closes iterator, triggers return
      }
  return isCheck.find((i: any) => i === false) !== false
}
export const funcSend = async(dataModal: any,setDataModal: any, user: any, staffInfo: any,storePrint: any, callBackFunc?: any, note?: any) => {
  popupLoading()
  // return
  let dataTransLine = await getDataTrans(dataModal, setDataModal, user, staffInfo)
  if(dataTransLine === false) {
      return
  }
  let lineItems = dataTransLine?.filter((i: any) => i.id && i?.kitchenstatus == KITCHENSTATUS.notSent)
  let ids = []//checkFilterAction([], KITCHENSTATUS.notSent)
  
  ids = lineItems.map((i: any) => i.id)
  let tempData = {
      kitchenstatus: KITCHENSTATUS.sent,//dataModal.transactionstatus,
      ids: ids,
      "staffcode": staffInfo.code,
      "staffname": staffInfo.fullname,
  }
  
  // setSelected([])
  // handleRefeshMenu()
  // setModalSend(false)
  
  if(ids.length == 0) {
      popupMessage({icon: 'info', title: t('order-deep.not-found'), description: t('order-deep.not-found-items-line-to-send-kitchen')})
      return
  }

  // return
  const method = 'put'
  const urlApi = `smarthub/hosptransactionlines/updatemulti/updatekitchenstatus`
  let check = await checkConnectPrinterToSend(dataModal, lineItems, staffInfo)
  
  // console.log('check',check)
  if(process.env.REACT_APP_ACTIONSEND) {
      check = true
  }
  if(!check) {
      popupMessage({icon: 'info', title: t('order-deep.failed!'), description: t('order-deep.failed-to-connect-to-printer')})
      return
  }
  else {
      try {
          let {data} = await ApiService[method](urlApi,tempData)
          let tempLines = formatReturnKitchenStatus(JSON.parse(JSON.stringify(dataTransLine)),data||{},ids,KITCHENSTATUS.notSent)
          // setItemLines(tempLines)
          // console.log('tempLines',tempLines)
            let username = (await getAuth() as any).user?.username

          if(!data?.Transactionlines) {
              let tempData = {
                  data: data,
                  funcName: 'Chế biến data trả về (actionSend)',
                  error: 'Errors',
                  staffcode: staffInfo?.code,
                  username: username,
              }
              ApiService.post(`smarthub/logs/write`, tempData)
            //   popupMessage({icon:'info',description: 'Kiểm tra lại data đồng bộ line',})
              Swal.close()
              return
          }
          let a = data?.Transactionlines?.map((i: any) => i.id) || ids
          let b = tempLines.filter((i: any) => a?.includes(i.id) )
          setDataModal({...dataModal,HospTransactionLines: tempLines})//tempLines = []
          findAndPrint(b,{
              ...dataModal,
              message: note ? note : dataModal.message,
              dataInfo: {
                  staffInfo: staffInfo,
              }
          }, {
              PrinterList: storePrint.PrinterList,
              setPrinterlist: storePrint.setPrinterlist,
              staffInfo: staffInfo,
          })

          popupMessage({ icon: 'success', autoClose: true })
          if (ids.length != 0 && dataModal.receiptno) {
              updateOnERP(dataModal,staffInfo)
          }
      
      } catch (error) { 
          ApiShowError(error,'actionSend',staffInfo) 
      }
      callBackFunc && callBackFunc()    //handleCloseModalNotAction()
  }
  
}
export const formatReturnKitchenStatus = (tempTtemLines: any, data: any, ids: any, status: any) => {
  let {Transactionlines,createdKotLines} = data
  tempTtemLines.forEach((item: any) => {
      if(ids.includes(item.id)) {
          let record = Transactionlines?.find((i: any) => i.lineno == item.lineno)
          let kotline = createdKotLines?.find((i: any) => i.hosptransactionlineid == record?.id)
          if(record) {
              item.kitchenstatus = record.kitchenstatus
          }
          if(kotline) {
              item.kotline = kotline
          }
      }
      // else {
      //     item.kitchenstatus = item.kitchenstatus
      // }
  })
  return tempTtemLines
}
export const converErrors = (errors: any) => { // [1000] lỗi abc: [code],[tableno]
  return errors.map((item: any) => {
      let text_arr = item.split('] ')
      let err = item
      if(text_arr.length > 1) {
          let param = text_arr[1]||''
          let code = text_arr[0]?.slice(1)||''
          if(code == "1000") {
              param = param.split(': ')
              if(param.length > 1) {
                  param = param[1].split(',')
                  // err = ``
              }
          }
          else if(code == "2000") {
              param = param.split(': ')
              if(param.length > 1) {
                  param = param[1].split(',')
                  if(param.length > 2) {
                      err = t('errors-erp.error-2000', { code: param[2], tableno: param[1]})//
                  }
              }
          }
          else if(code == "4000") {
              param = param.split(': ')
              if(param.length > 1) {
                  param = param[1].split(',')
                  if(param.length > 2) {
                      err = t('errors-erp.error-4000', { code: param[2], tableno: param[1]})//
                  }
              }
          }
          else if(code == "2100") {
            param = param.split(': ')
            if(param.length > 1) {
                param = param[1].split(',')
                if(param.length > 2) {
                    err = t('errors-erp.error-2100', { code: param[2], tableno: param[1]})//
                }
            }
          }
          else if(code == "2200") {
            param = param.split(': ')
            if(param.length > 1) {
                param = param[1].split(',')
                if(param.length > 2) {
                    err = t('errors-erp.error-2200', { code: param[2], tableno: param[1]})//
                }
            }
          }
      }
      return err
  })
}
export const applyFromErp = (iData: any, item: any) => {
    iData.discount = +item.DiscountPercent
    iData.discountamount = +item.DiscountAmount
    iData.price = +item.Price
    iData.netprice = +item.NetPrice
    iData.netamount = +item.NetAmount
    iData.couponcode = item.CouponCode
    iData.couponcode = +item.Barcode
    
    
    if(item.ItemDescription) {
        iData.description = item.ItemDescription
    }
    
    if(+item.TAXAmount) {
        iData.vatamount = +item.TAXAmount
    }
    if(true) {
        let amount = +item.NetAmount+ +item.TAXAmount
        iData.amount = amount
        iData.vat = ((amount - +item.NetAmount)*100/+item.NetAmount).toFixed(0)
    }
    if(+item.VATPercent) {
        iData.vat = +item.VATPercent
    }
    return iData
}
export const funcCalculateBasket = async(dataModal: any, setDataModal: any, setIsloading: any,setMobileTransBenefitLine: any,promoCode?: any) => {
    // console.log(' dataModal',dataModal)
    if(!dataModal || !dataModal.id) return
    setIsloading && setIsloading(true)
    let tempCoupon = promoCode||[]
    if(dataModal.coupon) {
        tempCoupon = unique_arr(tempCoupon.concat(dataModal.coupon),'code')
    }
    try {
        const urlApi = `lscentral/hosporders/calculatetransaction`
        let tempData = {
            transactionid: dataModal.id,
            "HospTransDiscountLines": tempCoupon && tempCoupon.length != 0 ? tempCoupon.map((item: any,index: number) =>({
                "storeno": dataModal.storeno,
                "linetype": 6,
                "lineno": index+1,
                "barcodeno": item.code,
                "quantity": 1,
                "entrytype": "Coupon",
                
            })):undefined,
        }
        let {data} = await ApiService.post(urlApi, tempData)
        setIsloading && setIsloading(false)
        // console.log('data',data)
        let HospTransactionLines = dataModal.HospTransactionLines
        // let ERPLine = [] as any
        if(data && data.SoapBody && data.SoapBody.HospOrderCalculate_Result)  {
            let HospOrderCalculate_Result = data.SoapBody.HospOrderCalculate_Result || {}
            let {errorText,mobileTransactionXML} = HospOrderCalculate_Result
            if(errorText.length != 0) {
                popupMessage({icon:'error',description: errorText})
                return
            }
            if(mobileTransactionXML) {
                let {MobileTransaction, MobileTransactionLine, MobileTransDiscountLine, MobileTransBenefitLine} = mobileTransactionXML
                if(!Array.isArray(MobileTransDiscountLine) && MobileTransDiscountLine) {
                    MobileTransDiscountLine = [MobileTransDiscountLine]
                }
                if(!Array.isArray(MobileTransactionLine) && MobileTransactionLine) {
                    MobileTransactionLine = [MobileTransactionLine]
                }
                if(!Array.isArray(MobileTransBenefitLine) && MobileTransBenefitLine) {
                    MobileTransBenefitLine = [MobileTransBenefitLine]
                }
                if(MobileTransBenefitLine && MobileTransBenefitLine.length != 0) {
                    setMobileTransBenefitLine && setMobileTransBenefitLine(MobileTransBenefitLine)
                }
                if(MobileTransDiscountLine && MobileTransDiscountLine.length != 0) {
                    let coupon = MobileTransDiscountLine.filter((i: any) => i.DiscountTypeText == 'Coupon') as any
                    
                    if(coupon && coupon.length != 0) {
                        coupon.forEach((item: any) => {
                            let record = MobileTransactionLine.find((i: any) => i.LineNo == item.LineNo)
                            if(record) {
                                let indexTranLine = HospTransactionLines.findIndex((i: any) => i.lineno == record.LineNoExt)
                                if(indexTranLine > -1 && item.DiscountAmount) {
                                    HospTransactionLines[indexTranLine].coupondiscountamount = item.DiscountAmount
                                    HospTransactionLines[indexTranLine].coupondiscountpercent = item.DiscountPercent
                                }
                            }
                            
                        })
                    }
                    if(MobileTransactionLine && MobileTransactionLine.length != 0) {
                        HospTransactionLines.forEach((item: any) => {
                            // let tempHospTransactionLines = {
                            //     id: HospTransactionLines[indexTranLine].id
                            // } as any
                            if(item.customizations && item.customizations.length != 0) {
                                item.customizations.forEach((i: any) => {
                                    let tempCus = MobileTransactionLine.find((m: any) => m.LineNoExt == i.lineno)
                                    if(tempCus) {
                                        i = applyFromErp(i,tempCus)
                                    }
                                })
                            }
                            if(item.quantity == 1) {
                                let tempLine = MobileTransactionLine.find((m: any) => m.LineNoExt == item.lineno)
                                if(tempLine) {
                                    item = applyFromErp(item,tempLine)
                                }
                            }
                            else {
                                let tempLine = MobileTransactionLine.filter((m: any) => m.LineNoExt == item.lineno)
                                // console.log('tempLine',tempLine)
                                if(tempLine && tempLine.length != 0) {
                                    let tempLineWithMulti = tempLine[0] as any
                                    tempLine.forEach((i: any,index: number) => {
                                        if(index != 0) {
                                            tempLineWithMulti.TAXAmount =+tempLineWithMulti.TAXAmount + +i.TAXAmount
                                            tempLineWithMulti.NetAmount =+tempLineWithMulti.NetAmount + +i.NetAmount
                                            tempLineWithMulti.DiscountAmount =+tempLineWithMulti.DiscountAmount + +i.DiscountAmount
                                        }
                                    })
                                    // console.log('tempLineWithMulti',tempLineWithMulti)
                                    item = applyFromErp(item,tempLineWithMulti)
                                }
                            }
                            
                        })

                    } 
                }
                // console.log('ERPLine',ERPLine)
                // let temp = JSON.parse(JSON.stringify(dataModal))
                //MobileTransaction
                console.log('HospTransactionLines',HospTransactionLines)
                let dataAmount = caculPromo(HospTransactionLines,dataModal)
                let DiscountAmount = +MobileTransaction?.LineDiscount + +MobileTransaction?.ManualTotalDiscAmount + +MobileTransaction?.TotalDiscount
                let temp = {
                    id: dataModal?.id,
                    coupon: tempCoupon,
                    "netamount": MobileTransaction?.NetAmount,
                    "taxamount": dataAmount.taxamount,
                    "grossamount": MobileTransaction?.GrossAmount,
                    linediscount: +MobileTransaction?.LineDiscount, 
                    // manualtotaldiscount: +MobileTransaction?.ManualTotalDiscAmount, 
                    discountamount: DiscountAmount,
                    "totaldiscount": +MobileTransaction?.TotalDiscount,
                    "discountlines": MobileTransDiscountLine?.map((i: any) => {
                        let item = MobileTransactionLine?.find((l: any) =>l.LineNo == i.LineNo)||{}
                        return {...i,Description: i.DiscountTypeText == 'Coupon'?i.DiscountTypeText : i.Description, LineNoExt: item.LineNoExt, ItemNo: item.Number}
                    }),
                    "HospTransactionLines": HospTransactionLines,
                }
                setDataModal({
                    ...dataModal,
                    ...temp
                })
                funcUpsertwithdetails(temp, setDataModal)
            }
        }
        
    } catch (error) { 
        setIsloading(false)
        ApiShowError(error)
    }
}
export const funcUpsertwithdetails = async(tempData: any,setDataModal: any,setIsloading?: any,setMobileTransBenefitLine?: any, isCaculate?: any) => {
            
    let HospTransactionLines = tempData.HospTransactionLines
    let a = HospTransactionLines.filter((i: any) => i.id)
    let b = HospTransactionLines.filter((i: any) => !i.id)
    if(a.length != 0) {
        a = unique_arr(a,'id')
    }
    tempData = {
        ...tempData,
        HospTransactionLines: a.concat(b)
    }
    try {
        let {data} = await ApiService.post(`/smarthub/hosptransactions/upsertwithdetails/`, tempData)
        if(data.errors && data.errors.length != 0) {
            let errors = converErrors(data.errors) as any
            popupMessage({icon: 'info', description: errors.join('; ')})
        }
        if(isCaculate === true) {
            funcCalculateBasket({
                ...tempData
            },setDataModal,setIsloading,setMobileTransBenefitLine)
        }
        if(data && data.upsertedHospTransaction ) {
            setDataModal && setDataModal((current: any) => {
                return {
                    ...current,
                    receiptno: data.upsertedHospTransaction.receiptno ? data.upsertedHospTransaction.receiptno : current.receiptno,
                    transactiontime: data.upsertedHospTransaction.transactiontime,
                    HospTransactionLines: current.HospTransactionLines?.map((i: any) => {
                        let item = data.upsertedHospTransaction.HospTransactionLines?.find((f: any) => f.lineno == i.lineno)
                        if(item) {
                            i.id = item.id
                            i.kitchenstatus = item.kitchenstatus
                        }
                        
                        return i
                    })

                }
            })
        }
        // setIndexEdit(-1)
    } catch (error) { 
        ApiShowError(error)
    }
    

}
export const getInforDataToPrint = async(id: any, staffInfo: any,storePrint: any,typePrint?: any) => {
    // typePrint: undefined => 1
    // 1: print order 
    // 2: print ['BAR','KITCHEN']
    // 3: print einvoice
    // 4: print Pre-print
    try {
        const urlApi = `smarthub/hosptransactions/${id}?expand=hosptransactionlines,hospitem,TransactionPaymentEntries`
        let {data} = await ApiService.get(urlApi)
        if(data) {
            let itemLine = data.HospTransactionLines.filter((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)
            if(typePrint == 4) { // kiểm món
                prePrint(itemLine,{
                    ...data,
                    typePrint: typePrint,
                    dataInfo: {
                        staffInfo: staffInfo,
                    }
                }, {
                    PrinterList: storePrint.PrinterList,
                    setPrinterlist: storePrint.setPrinterlist,
                    staffInfo: staffInfo,
                })
            }
            else if (typePrint == 3) { // einvoice
                printReceipt(itemLine,{
                    ...data,
                    typePrint: typePrint,
                    dataInfo: {
                        staffInfo: staffInfo,
                    }
                }, {
                    PrinterList: storePrint.PrinterList,
                    setPrinterlist: storePrint.setPrinterlist,
                    staffInfo: staffInfo,
                })
            }
            else { // in order not einvoice
                findAndPrint(itemLine,{
                    ...data,
                    typePrint: !typePrint ? 1 : typePrint,
                    dataInfo: {
                        staffInfo: staffInfo,
                    }
                }, {
                    PrinterList: storePrint.PrinterList,
                    setPrinterlist: storePrint.setPrinterlist,
                    staffInfo: staffInfo,
                })
            }
            
        }
        
    } catch (error) { ApiShowError(error, 'getInforDataToPrint', staffInfo) }
}
export const notifyPayment = async(
    dataNotify: any, 
    dataEdit: any, 
    showPayTemplate: any, 
    setShowPayTemplate: any,
    resetTableView: any,
    staffInfo: any,
    storePrint: any,
    transferTable?: any,
    cancelTableOccupied?: any, 
    setIsCallLock?: any,
    ) =>{
        // const resetTableView = () => {
        //     setOrderActive(-1)
        //     setEdit(false)
        //     refetchOrderService()
        //     setShowTable(false)
        //     setShowTransferTable(false)
        // }
        // console.log('dataNotify',dataNotify)
        let infoSetupPrint = await localforage.getItem("merchantsData")||{} as any
        let {receiptsetups} = infoSetupPrint||{}
        // console.log('receiptsetups.closedcheckreceipt',receiptsetups.closedcheckreceipt)
        if(dataNotify?.einvoice) {
            // if(receiptsetups.closedcheckreceipt == 'printInvoice') {
            //     console.log('print einvoice')
            //     getInforDataToPrint(dataNotify.id, staffInfo, storePrint,3)
            // }
            return
        }
        if(!dataNotify || !dataEdit) return
        let username = (await getAuth() as any).user?.username
        
        // console.log('username',username)
        let {code,notifycode,tableno} = dataNotify
        let modal_payment = document.getElementById('modal_payment')
        if(notifycode == 'Failed' && dataNotify.code == dataEdit.code) {
            let message = t('payment-failled')
            if(showPayTemplate || modal_payment) {
                popupMessage({ icon: 'error', title: `#${code} ${message}` })
            }
            return
        }

        let createdPaymententries = dataNotify.createdPaymententries as any
        
        if(createdPaymententries && isArray(createdPaymententries) && createdPaymententries.length != 0) {
            if(dataNotify.transactionstatus == 'Completed' && username == dataNotify.username ) {
                if(receiptsetups.closedcheckreceipt == 'printPaymentReceipt') {
                    console.log('print bill')
                    getInforDataToPrint(dataNotify.id, staffInfo, storePrint)
                }
                //print
            }
            let notifycode = 'Success'
            let message = t('payment-success')
            let data = createdPaymententries[0] as any
            // let {amounttendered} = data
            let grossamount = dataNotify.grossamount
            let {orderInfo} = data.ipn||{}

            // console.log('1111111111111111',showPayTemplate)
            
            
            if(dataNotify?.code == dataEdit?.code && (showPayTemplate || modal_payment)) {
                popupMessage({ icon: 'success', title: `#${code} ${t('table')} ${tableno} ${message}: ${formatCurrent(grossamount)} VNĐ`,  timer: 3000, autoClose: true} as any)
                setTimeout(()=>{
                    setShowPayTemplate(false)
                },1500)
            }

            else if(data.ipn) { // momo return
                if (orderInfo) {
                    popupAlertBottom(`#${code} ${t('table')} ${tableno} ${message}: ${formatCurrent(grossamount)} VNĐ`, notifycode == 'Success'?'success':'error')
                }
                else { // momo manual
                    // let notifycode = 'Success'
                    // let message = 'Thanh toán thành công'
                    popupAlertBottom(`#${code} ${t('table')} ${tableno} ${message}: ${formatCurrent(grossamount)} VNĐ`, notifycode == 'Success'?'success':'error')
                }
            }
            else { // cash
                // let notifycode = 'Success'
                // let message = 'Thanh toán thành công'
                popupAlertBottom(`#${code} ${t('table')} ${tableno} ${message}: ${formatCurrent(grossamount)} VNĐ`, notifycode == 'Success'?'success':'error')
            }
            
        }
        if(dataNotify.islocked && dataNotify.notifycode == '200-5000' && dataNotify.tableno == dataEdit.tableno) {
            if(showPayTemplate || modal_payment) {
                setShowPayTemplate(false)
            }
        }
        if(dataNotify.code == dataEdit.code && dataEdit.code && dataNotify.notifycode == '200-3000' && (!dataNotify.lockinfo || dataNotify.transactionstatus == 'Completed')) {//reset table
            if(dataNotify.tableno == dataEdit.tableno ) {
                resetTableView()
            }
        }
        // else if(!dataNotify.islocked && dataNotify.notifycode == '200-5000') {
        //     // console.log('transferTable',transferTable)
        //     if(dataNotify.tableno == transferTable?.tableno ) {
        //         // console.log('dataEdit',dataEdit)
        //         if(dataEdit.lockinfo) {
        //             callLockTable && callLockTable({
        //                 transactionid: dataEdit?.id,
        //                 lockinfo: null,
        //             },setIsCallLock)
        //             resetTableView()
        //             setShowPayTemplate(false)
        //         }
        //     }
        //     else if(dataNotify.tableno == dataEdit.tableno && transferTable) {
        //         // console.log('333333333333')
        //         cancelTableOccupied && cancelTableOccupied({id: transferTable?.tableid})
        //         resetTableView()
        //     }
        // }
    }
export const checkPermissionPayment = (currentUser: any, staffPermissionGroupInfo: any) => {
    let check = false
    if (currentUser && currentUser.visibleto) {
        let arrVisible = currentUser.visibleto.split(',')
        let key = 'payment-terminal'
        if(staffPermissionGroupInfo.isaddpayment && arrVisible.includes(key)) {
          check = true
        }
    }
    return check
}