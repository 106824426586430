import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, formatCurrent, sortByNumber, sortWithDate } from "../../../../theme/helpers";
import { FilterDropdown, KTButton, KTSVG, SearchTable } from "../../../../theme/partials";
import { useAuth } from "../../../modules/auth";
import { popupMessage, popupQuestion } from "../../../modules/messages";
// import { add15MinutesToTime, converDataWithCustom } from "./functionOrder";
import { Dropdown } from "react-bootstrap";
import { useStoreMode, useStorePrint } from "../../../modules/auth/core/store";
import { checkPaid } from "../../orderManagement/tabs/activeOrder";
import { ModalAddEditOrder } from "../../orderManagement/tabs/addOrder";
import { CountUpTimer, cancelOrder, checkPermissionPayment, converDataWithCustom, funcChangeStatusTrans, getInforDataToPrint, handleClickGetItemKOT, notifyPayment } from "../../orderManagement/tabs/functionOrder";
import { DetailItem, MemberInforAndNote, ModalPay, NoDataTemplate, PromotionAndSum, TRANSACTIONSTATUS } from "../../orderManagement/tabs/templateOrder";
import { Trans, useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { badgeSearch } from "../../orderManagement/tabs/live";
import { salestype_arr } from "..";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});

const tabs = [
    { id: 'Open', status:'Open', name: <Trans i18nKey='payment-terminal-deep.open'/>,},
    { id: 'Completed', status:'Completed', name: <Trans i18nKey='payment-terminal-deep.paid'/>,  },
    { id: 'Cancel', status:'Cancel', name: <Trans i18nKey='payment-terminal-deep.cancel'/>,  },
  ]
  
  
const urlApi = 'settings/customers'
const MBar = (props:any) => {
    const { t } = useTranslation()
    const { currentUser } = useAuth() as any
    const {tab, setTab, setSortByFilter, sortByFilter, setKeyword, dataApi,filter,setFilter, isSearch, refetchData,callBackFunc,dataCount, isloading: loadingData, pageOption, setPageOption, refetchDataTab, dataNotify} = props
    // const { permission } = getLocationData()
    let storePrint = useStorePrint((e: any)=> e)||{}
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    
    const navigate = useNavigate();
    const [edit ,setEdit] = useState<any>(false)
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(0)
    const [dataEdit ,setDataEdit] = useState<any>({})
    const [showPayTemplate,setShowPayTemplate] = useState(false)
    const [searchOn,setSearchOn] = useState(false)
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}

    const [isloading,setIsloading] = useState(false)
    const [hasMore,setHasMore] = useState(true)
    
    
    
    useEffect(() => {
        // console.log('dataApi',dataApi)
        if(dataApi) {
            getData(dataApi)
        }
    }, [dataApi])
    
    const fetchMoreData =(props: any) => {
        // console.log('props',props)
        // console.log('dataQuery',dataQuery)
        // console.log('pageOption',pageOption)
        if(dataQuery.length < pageOption.total) {
            refetchDataTab(tab,{...pageOption,page: pageOption.page + 1})
        }
        else {
            // console.log('ấdasdsa')
            setHasMore(false)
        }
    }
    useEffect(() => {
        if(props.filter) {
            // console.log('filter',props.filter)
        }
    }, [props.filter])
    const [note,setNote] = useState('')
    const getData = (data: any, sortBy?: any) => {
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
            if(!(item.tablename && item.sectionname)) {
                item.tablename = 'Quick Order'
            }
        })
        
        let sort = sortBy || sortByFilter
        if(sort) {
            if(sort == 'code') {
                data = sortByNumber(data,'code','DESC')
            }
            else if (sort == 'oldest_time'){
                data = sortWithDate(data,{sort: 'modifiedat', order: 'asc'})
            }
            else if (sort == 'most_time'){
                data = sortWithDate(data,{sort: 'modifiedat', order: 'desc'})
            }
            else if (sort == 'tableno'){
                data = sortByNumber(data,'tableno')

            }
        }
        if(data.length == 0) {
            setDataEdit(null)
            setOrderActive(-1)
        }
        else if(dataEdit && dataNotify && dataEdit?.id == dataNotify?.id
        && (dataNotify?.transactionstatus != TRANSACTIONSTATUS.completed 
            && dataNotify?.transactionstatus != TRANSACTIONSTATUS.cancel 
            && dataEdit?.transactionstatus != TRANSACTIONSTATUS.completed 
            && dataEdit?.transactionstatus != TRANSACTIONSTATUS.cancel)) {
            // handleClickGetItemKOT(data[0],setDataEdit,setNote, setIsloading)
            // setOrderActive(0)
        }
        else {
            // console.log('data[0]z',data[0])
            handleClickGetItemKOT(data[0],setDataEdit,setNote, setIsloading)
            setOrderActive(0)
            document.querySelector('.infinite-scroll-component')?.scrollTo(0,0)
        }
        setDataQuery(data)
    
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })
    const refetchPayOrder = () => {
        getDetailOrder(dataEdit)
    }
    const setSortBy = (value: any) => {
        setSortByFilter(value)
        getData(dataApi, value)
    }
    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>

    const checkSearch = (value: any) => {
        return filter[value]
    }
    const getDetailOrder = async(item: any) => {
        if(item) {
            handleClickGetItemKOT(item,setDataEdit,setNote, setIsloading)
        }
          
        
    }
    const funcSearch = (keyword: any) => {
        setKeyword(keyword)
        refetchDataTab(tab, pageOption, keyword)
    }
    const filterBtn = <>
    <FilterDropdown
    btnTemplate={<div className="btn btn-light-primary btn-active-primary btn-sm btn-icon">
      <KTSVG path={'/media/icons/custom/filter.svg'} className={clsx('svg-icon-2 svg-icon')} />
    </div>
    }
    width="400"
    onClose={() => {
        setFilter(undefined)
        reset({})
        }
    }
    handleSubmit={handleSubmit}
    onSubmit={(data:any) => {
        setKeyword('')
        setFilter(data)
       
    }} >
        <div className="container-fluid mb-2">
            <div className="row">
              <div className="col-12 fs-5 mb-3">{t('payment-terminal-deep.sort-by')}</div>
              <div className="d-flex gap-3">
                <span className={clsx(`badge badge-${sortByFilter== 'code'?'primary':'secondary'} cursor-pointer p-3 mb-3`)} onClick={e=>{setSortBy('code')}}>Transactions Code</span>
                <span className={clsx(`badge badge-${sortByFilter== 'most_time'?'primary':'secondary'} cursor-pointer p-3 mb-3`)} onClick={e=>{setSortBy('most_time')}}>{t('payment-terminal-deep.most-recent')}</span>
                <span className={clsx(`badge badge-${sortByFilter== 'oldest_time'?'primary':'secondary'} cursor-pointer p-3 mb-3`)} onClick={e=>{setSortBy('oldest_time')}}>{t('payment-terminal-deep.oldest')}</span>
                <span className={clsx(`badge badge-${sortByFilter== 'tableno'?'primary':'secondary'} cursor-pointer p-3 mb-3`)} onClick={e=>{setSortBy('tableno')}}>{t('payment-terminal-deep.table-number')}</span>
              </div>
              <div className="col-12 fs-5 mb-3">{t('payment-terminal-deep.filter-by')}</div>
              <div className="col-12 col-sm-6">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer flex-start">
                  <input className="form-check-input" type="checkbox" {...register('takeaway', {
                        onChange: (e) => {} })} value={1} id="takeaway" />
                  <label htmlFor="takeaway">Takeaway</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('dinein', {
                        onChange: (e) => {} })} value={1} id='dinein' />
                  <label htmlFor="dinein">Dine-in</label>
                </div>
              </div>
              <div className="col-12 col-sm-6  mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_delivery', {
                        onChange: (e) => {} })} value={1} id='is_delivery' />
                  <label htmlFor="is_delivery">{t('payment-terminal-deep.delivery')}</label>
                </div>
              </div>
              {/* <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_curbside', {
                        onChange: (e) => {} })} value='1' id='is_curbside' />
                  <label htmlFor="is_curbside">{t('payment-terminal-deep.curbside')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('no_behavior', {
                        onChange: (e) => {} })} value='1' id='no_behavior' />
                  <label htmlFor="no_behavior">{t('payment-terminal-deep.no-behavior')}</label>
                </div>
              </div> */}
            </div>
        </div>
      </FilterDropdown>
    </>
    const toolbarAction = <div className="d-flex flex-center gap-5">
        <Dropdown>
            <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
            <i className="bi bi-list-ul" />
            </Dropdown.Toggle>
            <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-2' >
                
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''>
                    <span className="menu-link">{t('payment-terminal-deep.manager-online-orders')}</span>
                    </a>
                </Dropdown.Item>
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''> 
                    <span className="menu-link">{t('payment-terminal-deep.find-checks-and-issue-result')}</span>
                    </a>
                </Dropdown.Item>
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''> 
                    <span className="menu-link">{t('payment-terminal-deep.switch-users')}</span>
                    </a>
                </Dropdown.Item>
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''> 
                    <span className="menu-link">{t('payment-terminal-deep.device-status')}</span>
                    </a>
                </Dropdown.Item>
                
            </Dropdown.Menu>
        </Dropdown>
        <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer"><i className="bi bi-search"></i></span>
      
    
    </div>
    const getCount = (status: string) => {
        return dataCount?.find((i: any) => i.transactionstatus == status)?.count || 0
      }
    
    return <div className={clsx("container-fluid",)}>
    <div className="row">
        <div className="col-12 col-lg-4 col-md-4">
        <form
            className="h-100 w-100"
            onSubmit={handleSubmit(async (data: any) => {
                console.log('onsubmit',data)
                
            })}>
            <div className={clsx("wrap-content-card position-sticky-custom",{'wrap-loading': loadingData})}>
                
                
                {
                    dataQuery && dataQuery.length != 0 ?
                    <div className="card-body pb-0">
                        {orderActive > -1 && dataEdit ? 
                            <div className="p-0 card shadow">
                                <h6 className="d-flex flex-stack px-3 pt-3">
                                    <span>{dataEdit.tablename} - {dataEdit.sectionname}</span>
                                    <div className="d-flex min-h-30px gap-1">
                                        {dataEdit.receiptno && <div className="d-flex flex-stack"> 
                                            <span>{dataEdit.receiptno}</span>
                                        </div>}
                                        {
                                            (staffPermissionGroupInfo.isvoidtransaction && tab == TRANSACTIONSTATUS.open) && 
                                            <span onClick={e=>cancelOrder(dataEdit,orderActive,dataQuery,setDataQuery, staffInfo)} className="btn btn-sm btn-icon btn-light btn-active-light-danger"><i className="bi bi-x-lg"></i></span>
                                        }
                                    </div>
                                </h6>
                                <div className={clsx({"loading-calculate":isloading,})}>
                                {
                                    isloading &&
                                    <div className="bg-blur"><div className="loader-cal"></div></div>
                                }
                                    <DetailItem {...dataEdit} note={note||dataEdit.message} />
                                    <div className="wrap-footerItem">
                                        <MemberInforAndNote note={note||dataEdit.message} dataModal={dataEdit} {...dataEdit} setDataModal={setDataEdit} />
                                        <PromotionAndSum {...dataEdit} paidPayment={dataEdit.TransactionPaymentEntries}/>
                                        
                                        <div className="d-flex gap-4 m-3">
                                            
                                            {
                                                !checkPaid(dataEdit).isPaid && tab != 'Cancel' ?
                                                <>
                                                {/* <button className="btn btn-sm btn-success w-100" onClick={e=>{

                                                }}><i className="bi bi-printer me-1"></i>{t('order-deep.print')}</button> */}
                                                {staffPermissionGroupInfo.isopensalepos && 
                                                    <button onClick={e=>{
                                                        setEdit(true)
                                                    }} className="btn btn-sm btn-primary w-100"><i className="bi bi-pencil me-1"></i>{t('payment-terminal-deep.update')}</button>
                                                }
                                                
                                                </>
                                                :
                                                <>
                                                {
                                                    tab == 'Cancel' ?<>
                                                    {dataQuery[orderActive]?.DiningTableHistoryEntries &&
                                                    <div className="btn btn-sm btn-gray my-2 w-100">{t('payment-terminal-deep.closed')} 
                                                        <span className="ps-2">{new Date(dataQuery[orderActive]?.DiningTableHistoryEntries[0]?.createddate).toLocaleString('en-GB', { hour12: false })}</span>
                                                    </div>}
                                                    </> :
                                                    <>
                                                    {
                                                        tab == TRANSACTIONSTATUS.open ? <button className="btn btn-sm btn-success w-100" onClick={async(e)=>{
                                                            funcChangeStatusTrans(dataQuery[orderActive],TRANSACTIONSTATUS.completed)
                                                            
                                                        }}><i className="bi bi-check me-1"></i>{t('orders-hub-deep.completed')}</button>
                                                        :
                                                        <button className="btn btn-sm btn-success w-100" onClick={async(e)=>{
                                                            let aws = await popupQuestion({title: t('orders-hub-deep.are-you-sure-you-print-receipt')})
                                                            if(aws) {
                                                                getInforDataToPrint(dataQuery[orderActive].id, staffInfo, storePrint, 3)
                                                            }
                                                            
                                                        }}><i className="bi bi-printer me-1"></i>{t('order-deep.receipt')}</button>
                                                    }
                                                    </>
                                                    
                                                }
                                                </>
                                                // <button onClick={e => completeOrder(dataEdit)} className="btn btn-sm btn-info w-100"><i className="bi bi-printer me-1"></i>Completed</button>

                                                
                                            }
                                            {
                                            checkPermissionPayment(currentUser,staffPermissionGroupInfo) &&
                                                !checkPaid(dataEdit).isPaid && tab != 'Cancel' &&
                                                <button onClick={e => setShowPayTemplate(true)} className="btn btn-sm btn-info w-100"><i className="bi bi-printer me-1"></i>{t('payment-terminal-deep.pay')} {formatCurrent(checkPaid(dataEdit).amount||0)}</button>

                                            }   
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                
                                
                            </div>
                            :
                            <NoDataTemplate className={'h-100 pt-4'}/>
                        }
                      
                    </div>
                    :
                    <NoDataTemplate className={'h-88vh pt-4'}/>
                }
                
            </div>
        </form>
        </div>
        <div className="col-12 col-lg-8 col-md-8 px-2 py-2 pt-0">
            <div className="d-flex flex-stack flex-row card  w-100 p-2 mb-3">
            <div
                className=' d-flex flex-row text-nowrap tab-custom-hub'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-width='auto'
                data-kt-scroll-offset='0'
              >
                {tabs.map((item, index) => {
                  return (<span
                    key={index}
                    id={item.id}
                    onClick={() => {
                      setTab(item.id)
                      setSearchOn(false)
                      setFilter(undefined)
                      salestype_arr.forEach((i: any) => {
                        setValue(i.value, false)
                      })
                      setDataEdit({})
                      setDataQuery([])
                      callBackFunc(item.status)
                    }}
                    className={clsx(
                      ' cursor-pointer item-tab position-relative min-w-100px',
                      tab != item.id && 'text-gray-600 ',
                      tab == item.id && 'active',
                      index == 0 && ''
                    )}>
                    {item.name} 
                    {/* <span className={clsx(`number-circle number-circle-c w-30px h-30px d-flex flex-center bg-${tab == item.id?'primary circle-custom':'gray right-3px'} position-absolute `)}>{getCount(item.status)}</span> */}
                  </span>)
                })}
              </div>
              
              <div className="d-flex gap-2">
                  {filterBtn}
                  <span className="btn btn-light-primary btn-active-primary btn-sm btn-icon cursor-pointer" onClick={e=> setSearchOn(true)}><i className="bi bi-search"></i></span>
              </div>
                
            </div>
            {searchOn && <div className="position-relative w-100 my-3">
                <SearchTable data={dataApi} returnFunc={funcSearch} 
                keySearch={['code','tablename','tableno','saletype','staffcode','grossamount','sectionname',]} />
                <span className="cursor-pointer close-event-s" onClick={e => {
                    setSearchOn(false)
                    setKeyword('')
                    refetchDataTab(tab, {
                        ...pageOption,
                        page: 1,
                    })
                    setDataQuery(dataApi)
                }}>{t('orders-hub-deep.cancel')}</span>
            </div>
            }
            {filter &&
                <div className="card-header min-h-auto d-flex gap-2">
                    {
                        badgeSearch.map((item: any,index: number) => (
                            checkSearch(item.value) && 
                            <span key={index} className="badge badge-gray d-inline-flex flex-center py-2 gap-1 mb-2"><span>{item.label}</span> <i onClick={e=>{
                                let a = JSON.parse(JSON.stringify(filter))
                                a[item.value] = false
                                setValue(item.value,false)
                                setKeyword('')
                                setFilter(a)
                            }} className="bi bi-x cursor-pointer"></i></span>
                        ))
                    }
                    
                </div>
            }
            <div className={clsx("position-relative",{'': loadingData})}>
                {
                    tab && dataQuery && dataQuery.length != 0 ? 
                    <InfiniteScroll
                    height={`calc(100vh - 120px)`}
                    dataLength={dataQuery.length}
                    next={fetchMoreData as any}
                    hasMore={dataQuery.length < pageOption.total}
                    loader={<div className='custom-loading'> <span className='loader'></span></div>}
                    >
                        <div className="row g-0 position-relative">
                            {
                            dataQuery && dataQuery.map((item: any,index: number) =>(
                                <div key={index} className="mb-4 px-2 wrap-item col-6" onClick={e=>{
                                    getDetailOrder(item)
                                    setOrderActive(index)
                                }}>
                                    <div className={clsx('h-100 item bg-primary bg-opacity-10 border-primary p-2 border border-2 d-flex',{'active bg-success border-success': orderActive == index})}>
                                        <div className="w-100px px-3 border-right-1 border border-left-0 border border-bottom-0 border border-top-0 border-gray d-flex flex-center">
                                            <div className="text-center">
                                                <span className="fs-3">#{item.code}</span>
                                                <div className="text-center"><span>{item.saletype}</span></div>
                                            </div>
                                        </div>
                                        <div className="p-2 w-100 wrap-table-inf">
                                            <div className="d-flex flex-stack mb-1">
                                                {
                                                    item.tablename && item.sectionname ?
                                                    <span>{item.tablename} - {item.sectionname}</span>
                                                    :
                                                    <span>{item.tablename}</span>
                                                }
                                                <span>{formatCurrent(item.grossamount||0)} đ</span>
                                            </div>
                                            <div className="d-flex gap-2 flex-stack">
                                                <div className="w-100">
                                                
                                                    {
                                                        tab != 'Open' && <div className={clsx(`mb-1`)}>
                                                            
                                                            <span className="text-end min-w-100px">
                                                                <span className="me-1"><i className="bi bi-check2-circle text-reset"></i>{tab == 'Completed'?' Paid':' Closed'}</span>
                                                                {new Date(item.modifiedat).toLocaleString('en-GB', { hour12: false })}
                                                            </span>
                                                            {/* {
                                                                item.receiptno &&
                                                                <span className="">{item.receiptno}</span>
                                                            } */}
                                                        </div>
                                                    }
                                                    {
                                                        tab == TRANSACTIONSTATUS.open && item.paid &&
                                                        <span className=""><i className="bi bi-check2-circle text-reset"></i> Paid</span>

                                                    }
                                                    <div className="d-flex flex-stack">
                                                        {
                                                            item.receiptno &&
                                                            <span className="">{item.receiptno}</span>
                                                        }
                                                        {
                                                            item.staffcode && 
                                                                <span className=""><i className="bi bi-person-hearts"></i> {item.staffcode}</span>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                                {
                                                    tab == 'Open' ?
                                                    <div className="d-flex gap-2 text-gray-600">
                                                        <div className="text-end min-w-100px">
                                                            <span className=""><i className="bi bi-clock"></i> </span>
                                                            <CountUpTimer starttime={new Date(`${item.transactiondate} ${item.transactiontime}`).getTime()} /></div>
                                                    </div>
                                                    :
                                                    <>
                                                    {
                                                        tab == 'Completed' && item.TransactionPaymentEntries && <div className="d-flex gap-2 text-gray-600">
                                                            
                                                            <div className="text-end min-w-100px">
                                                                <span className=""><i className="bi bi-cash"></i> </span>
                                                            {item.TransactionPaymentEntries[0]?.tendertypename}</div>
                                                        </div>
                                                    }
                                                    </>
                                                    
                                                    
                                                }
                                                
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    
                    </InfiniteScroll>
                    : <>
                        {
                            loadingData ? <div className='custom-loading'> <span className='loader'></span></div> :
                            <NoDataTemplate className={'h-81vh pt-4'}/>
                        }
                    </>

                }
                
                
            </div>
            
        </div>
    </div>
    {edit && 
        <ModalAddEditOrder dataNotify={dataNotify} refetchTable={refetchData} show={edit} dataModal={dataEdit} setDataModal={setDataEdit} refetchCurrent={refetchPayOrder} setShow={setEdit}/>
    }
    {
        showPayTemplate &&
        <ModalPay dataNotify={dataNotify} show={showPayTemplate} dataModal={dataEdit} setDataModal={setDataEdit} refetchCurrent={refetchPayOrder} setShow={setShowPayTemplate}/>
    }
        
    </div>
}

export { MBar };

